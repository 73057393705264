import React from "react";
import { motion } from "framer-motion";


const Legend = ({ data, selectedItems, onChange, staticItem, disabled }) => (
  <div className="flex justify-center w-full text-sm lg:text-md flex-wrap">
    {data.map((d) => (
      <motion.div
        className="checkbox mx-4"
        style={{
          textDecoration: "line-through"
        }}
        animate={{
          color: d.color,
          textDecoration: selectedItems.includes(d.name)
          ? "none"
          : "line-through",
          transition: { duration: 0.2, bounce: 0 }
        }}
        whileHover={{
          scale: disabled ? 1: 1.05,
        }}
        whileTap={{
          scale: disabled? 1 : 0.98
        }}
        key={d.name}>
        <motion.label>
          {d.name !== staticItem && (
            <input
              type="checkbox"
              value={d.name}
              checked={selectedItems.includes(d.name)}
              onChange={disabled ? () => ({}) : () => onChange(d.name)}
              style={{ display: "none" }}
            />
          )}
          {d.name}
        </motion.label>
      </motion.div>
    ))}
  </div>
);

export default Legend;
