import useFormState from "../../hooks/useFormState";
import ScenarioGroupForm from "./ScenarioGroupForm";
import { useCreateGroup } from '../../utils/api-utils';
import { v4 as uuidv4} from "uuid";
import { useNavigate } from 'react-router-dom';
import { withModal } from '../Modals/Modal';
import { tabURLs } from "../../constants";

const initialFormData = { name: "", editing: true };

export default function ScenarioGroupNew({ handleModal }) {
  const formState = useFormState(initialFormData);

  const url = tabURLs['partners']

  const { addGroup } = useCreateGroup(url)

  function handleAdd() {
    const newGroup = { 
      ...formState.state,
      id: uuidv4()
    }
    addGroup(newGroup);
    handleModal()
  }

  function handleCancel() {
    handleModal()
  }

  return <ScenarioGroupForm 
    formState={formState} 
    handleCreate={handleAdd} 
    handleCancel={handleCancel}
    url={url} />;
}

export const ScenarioGroupNewModal = withModal(ScenarioGroupNew);