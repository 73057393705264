
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, LabelList, Bar, ResponsiveContainer } from "recharts"
import PropTypes from "prop-types";
import { clientThemes } from "../../constants";
import { useEffect, useState, useMemo } from "react";
import { GridLoader } from "react-spinners";
import { motion, AnimatePresence } from "framer-motion";
import { IconButton } from "../../components/shared/Buttons";
import { FaPlay } from "react-icons/fa";
import { Inline } from "@bedrock-layout/inline";

import { format, max } from "d3";
import styled from "styled-components";

const placeholderData = [{
  id: "placeholder",
  saves: 0,
  redemptions: 0,
  partnerside: 0,
  uv: 0,
  partner: ""
}]

const formatSI = format(".2s")
const formatT = format(",")

const CustomLabel = styled.div``

export function LoadingOverlay({ data, fetching, handleFetch }) {

  const { isFetching, status } = fetching;



  const variants = {
    show: { opacity: 1, background: `rgba(141, 70, 50, 0)` },
    hide: { opacity: 0, background: `rgba(141, 70, 50, 0)` },
  }




  if (data.length > 0) {
    return null
  }



  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          style={{
            width: "50%",
            height: "100%",
            zIndex: 300,
            display: "flex",
            justifyContent: "center",
            padding: ".5rem",
            // border: "2px solid red"
          }}
          initial="hide"
          animate="show"
          exit={{ opacity: 0 }}
          // transition={{ duration: .1 }}
          variants={variants}
        >
          {data.length === 0 && status !== "fetching" ?
            (<motion.div
              variants={variants}
              initial="hide"
              animate="show"
              style={{ 
                borderRadius: "5px",
                fontSize: ".8rem",
                flex: "1 0 100%", 
                display: "flex",
                alignItems: "center", 
                justifyContent: "center"
            }}
              whileHover={{ scale: 1.1, 
                background: `rgba(141, 214, 161, .3)` 
              }}
              whileTap={{ scale: .9 }}
              exit={{ opacity: 0 }}
              gutter="md"
              onClick={(e) => {
                e.stopPropagation()
                handleFetch()
              }}
            >
              <span><FaPlay size={18} style={{ display: "inline-block", marginRight: "5px" }} color={clientThemes.quinary} /></span> 
              Click to run forecast.
            </motion.div>
            )
            : status === "fetching" || status === "success"
              ? (
                <motion.div initial="hide" animate="show" variants={variants} exit={{ opacity: 0 }} 
                style={{ 
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}>
                  <GridLoader size={10} color={clientThemes.secondary} />
                </motion.div>)

              : null

          }
        </motion.div>
      </AnimatePresence>
    </>
  )
}


const MotionInline = motion(Inline)

export function PlaceholderOverlay({ data, fetching, handleFetch }) {

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 }
  }

  return (
    <>

    </>

  )
}

const BAR_FONT = ".9rem"

export function ElementsChart({ data, domain }) {

  const [chartData, setChartData] = useState([]);

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 }
  }


  useEffect(() => {
    if (data.length === 0) {
      setChartData(placeholderData)
      return;
    }
    setChartData(data)
  }, [data])

  const chartDomain = useMemo(() => {
    if(domain.saves.length && domain.redemptions.length ) {
      return [0, max([domain.saves[1], domain.redemptions[1]])]
    }
  }, [domain])



  return (
    <motion.div
      initial="hide"
      animate="show"
      exit={{ opacity: 0 }}
      // transition={{ duration: .2 }}
      variants={variants}
      style={{ 
        height: "100%", width: "100%",
        // border: "2px solid red"
       }}
    >
      <ResponsiveContainer>

        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            top: 5,
            right: 10,
            left: 80,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" xAxisId="top" hide domain={chartDomain}/>
          <YAxis
            type="category"
            dataKey="partner"
            fontSize=".8rem"

          />
          <Tooltip 
          formatter={d => formatT(d)}
          wrapperStyle={{ zIndex: 9999}}
          />
          <Legend
            align="right"
            verticalAlign="middle"
            layout="vertical"
            wrapperStyle={{ fontSize: ".7rem", paddingLeft: "3rem" }}
            
          />
          <Bar xAxisId="top" dataKey="saves" fill={clientThemes.secondary} isAnimationActive={false} name="Saves">
            <LabelList
              dataKey="saves"
              position="right"
              style={{ fill: "black", fontSize: BAR_FONT }}
              formatter={d => formatSI(d)}
            />
          </Bar>
          <Bar xAxisId="top" dataKey="redemptions" fill={clientThemes.primary} isAnimationActive={false} name="Redemptions">
            <LabelList
              dataKey="redemptions"
              position="right"
              style={{ fill: "black", fontSize: BAR_FONT }}
              formatter={d => formatSI(d)}
            />
          </Bar>
          <Bar xAxisId="top" dataKey="partnerside" fill={clientThemes.quaternary} isAnimationActive={false} name="Partner Redemptions">
            <LabelList
              dataKey="partnerside"
              position="right"
              style={{ fill: "black", fontSize: BAR_FONT }}
              formatter={d => formatSI(d)}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </motion.div>


  )
}


ElementsChart.propTypes = {
  data: PropTypes.array
}

