import styled from "styled-components"
import {motion} from "framer-motion"
import { buttonBg, buttonText, buttonHoverBg, buttonHoverText } from "../../constants"


const buttonStyles = {
  textTransform: "uppercase",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: buttonBg,
  color: buttonText,
  borderRadius: "10px",
  outline: "0",

  border: "none",
  cursor: "pointer",
}

export const SimpleButton = (props) => {
  return (
    <motion.button
      style={buttonStyles}
      whileHover={{
        scale: 1.2,
        backgroundColor: "#333",
        color: "#FFF",
        transition: { duration: 0.1 },
      }}
      whileTap={{
        scale: 0.9,
      }}
      {...props}
    />
  )
}


export function IconButton({ label, icon, onClick,  children, className, disabled, testid, ...props}) {
  return (
    <motion.button
      className={`ring-1 ring-white ring-opacity-20 tracking-tight block
      rounded-xl
      font-bold
      mx-4 text-xs
      py-2 px-4`}
      style={buttonStyles}
      disabled={disabled}
      onClick={onClick}
      whileHover={{
        // scale: 1.07,
        backgroundColor: buttonHoverBg,
        color: buttonHoverText,
        transition: { duration: 0.1 },
      }}
      whileTap={{
        scale: 0.9,
      }}
      {...props}
      data-testid={testid}
    >
      <div
        className="flex items-end"
      >
        <span className="leading-none">
          {icon}
        </span>
        <span className={`${icon ? "ml-2" : ""} leading-none`}>{label}</span>
      </div>
    </motion.button>
  );
}

