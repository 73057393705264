import React, { useMemo, useState } from 'react';

import PropTypes from "prop-types";
import { Bar } from 'react-chartjs-2';
// import Grid from '@material-ui/core/Grid';
import { motion } from "framer-motion";
import { timeParse, timeFormat, format } from "d3";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { clientThemes, defaultText, interpolate } from "../../constants";
import { ToggleButtons } from '../../components/shared/ToggleButtons'
import { ExpandingTable } from "../../components/Charts/ExpandingTable";
import { PageSpinner } from "../../components/shared/Spinners";
import { avgDailyParticipants } from "../../constants"
import { useAtom } from 'jotai';
import { groupAtom } from './ScenarioGroupsView';
import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import { PadBox } from '@bedrock-layout/padbox';
import { Grid } from '@bedrock-layout/grid'
import { groupBy, mean, mutate, sum, summarize, count, tally, tidy, distinct, filter, mutateWithSummary } from '@tidyjs/tidy';


export const vmax = (d, a, b) => d?.[a] > d?.[b] ? d?.[b] : d?.[a]

const metricOptionLabels = [
  { value: 'Rate', label: 'Rate' },
  { value: 'Volume', label: 'Volume' }
]

const columnDefs = [
  { title: 'Partner', field: 'partner', defaultGroupOrder: 0 },
  { title: 'Day', field: 'date' },
  { title: 'Category', field: 'category' },
  { title: 'SubCategory', field: 'subcategory' },
  { title: 'Offer Construct', field: 'offer_construct' },
  { title: 'Redemption Volume', field: 'redemptions_mean', type: 'numeric' },
  { title: 'Redemption Volume (Lower CI)', field: 'redemptions_lower', type: 'numeric' },
  { title: 'Redemption Volume (Upper CI)', field: 'redemptions_upper', type: 'numeric' },
  { title: 'Save Volume', field: 'claims_mean', type: 'numeric' },
  { title: 'Save Volume (Lower CI)', field: 'claims_lower', type: 'numeric' },
  { title: 'Save Volume (Upper CI)', field: 'claims_upper', type: 'numeric' },
]

const toggleButtons = [
  { name: "Rate", color: clientThemes.primary },
  { name: "Volume", color: clientThemes.secondary },
]
const getName = o => o.name

export default function AnimatedToggle({ tabs, selected = "Volume", setSelected }) {

  const selectedTab = useMemo(() => tabs.find(o => o.name === selected), [selected]);
  const [formerColor, setFormerColor] = useState(selectedTab.color)



  return (
    <Inline gutter="sm" align="center" justify='center'>
      {tabs.map(({ name, color }, i) => (
        <motion.div
          style={tabStyle}
          key={name}
          initial={{
            color: name === selected ? "#fff" : "#222",
          }}
          animate={{
            color: name === selected ? "#fff" : "#222",
          }}
          // transition={{ duration }}
          onTap={(e) => {
            setFormerColor(selectedTab.color)
            setSelected(name)
          }}
        >
          <span style={{ position: "relative", zIndex: 1 }}>
            {name}
          </span>
          {name === selected && (
            <motion.div
              style={selectionStyle}
              layoutId="selected"
              initial={{ backgroundColor: formerColor }}
              animate={{ backgroundColor: color }}
            />
          )}
        </motion.div>
      ))}
    </Inline>
  )
}


const tabStyle = {
  height: 30,
  position: "relative",
  padding: ".4rem .8rem",
  margin: 0,
  fontFamily: "sans-serif",
  fontSize: ".7rem",
  // fontWeight: 500,
  color: "#222",
  cursor: "pointer",
}

const selectionStyle = {
  width: "100%",
  height: "100%",
  position: "absolute",
  borderRadius: 15,
  top: 0,
  left: 0,
}


const parseTimeYMD = timeParse("%Y-%m-%d")
const formatTimeDay = timeFormat("%a")

export function ScenarioGroupOutput() {


  const [groupData, _] = useAtom(groupAtom);
  const resultData = useMemo(() => groupData?.result?.[0] || {}, [groupData])
  const infoData = useMemo(() => resultData?.infoData?.[0] || [], [resultData])



  const infoDataCalc = useMemo(() => {
    const results = resultData?.tableData || [];

    return tidy(
      results,
      filter(d => formatTimeDay(parseTimeYMD(d.date)) === "Tue"),
      summarize({
        n_partners: distinct(["partner"]),
        avg_redemptions: mean('redemptions_mean'),
        avg_redemptions_volume: mean('redemptions_mean_volume'),
        avg_claims: mean('claims_mean'),
        avg_claims_volume: mean('claims_mean_volume'),
        avg_partnerside: mean('partnerside_mean'),
        avg_partnerside_volume: mean('partnerside_mean_volume'),
        // date_range: 
      }),
      mutate({
        n_partners: d => d.n_partners.length,
      })
    )
  }, [resultData])



  const [metricAlignment, setMetricAlignment] = useState('Volume');



  if (!resultData || !infoData || infoData.length === 0) {
    return (
      <p>
        No data available. Run the forecast to view output data.
      </p>
    )
  }


  resultData.barData = resultData?.barData
    .map(o => {
      return {
        ...o,
        avg_redemptions: o?.avg_redemptions ?? 0,
        avg_claims: o?.avg_claims ?? 0,
        avg_partnerside: o?.avg_partnerside ?? 0,
      }
    })

  resultData.infoData = resultData?.infoData
    .map(o => {
      return {
        ...o,
        avg_redemptions: o?.avg_redemptions ?? 0,
        avg_claims: o?.avg_claims ?? 0,
        avg_partnerside: o?.avg_partnerside ?? 0,
      }
    })

  resultData.tableData = resultData?.tableData
    .map(o => {
      return {
        ...o,
        redemptions_mean: o?.redemptions_mean ?? 0,
        claims_mean: o?.claims_mean ?? 0,
        partnerside_mean: o?.claims_mean ?? 0,
      }
    })


  const reactChartLabels = resultData?.barData.map((el) => {
    return (
      el.partner
    )
  }).flat(1)

  const reactChartRedemptionData = resultData?.barData.map((el) => {
    const redemptions_no = metricAlignment === "Volume" ? el.avg_redemptions_volume : ((el.avg_redemptions * 100).toFixed(2))
    return (
      // el.avg_redemptions
      redemptions_no
    )
  }).flat(1)

  const reactChartClaimData = resultData?.barData.map((el) => {
    const claims_no = metricAlignment === "Volume" ? el.avg_claims_volume : ((el.avg_claims * 100).toFixed(2))
    return (
      // el.avg_claims
      claims_no
    )
  }).flat(1)


  const reactChartPartnersideData = resultData?.barData.map((el) => {
    const partnerside_no = metricAlignment === "Volume" ? el.avg_partnerside_volume : ((el.avg_partnerside * 100).toFixed(2))
    return (
      // el.avg_claims
      partnerside_no
    )
  }).flat(1)

  // const colorList = reactChartLabels.map((item, i) => d3.interpolateSpectral(i / reactChartLabels.length))
  const vars = ["avg_claims", "avg_redemptions", "avg_partnerside"]
  const colorList = vars.map((item, i) => interpolate((i + 2) / (vars.length + 2)))

  const data = {
    labels: reactChartLabels,
    datasets: [
      {
        label: 'Save ' + metricAlignment,
        data: reactChartClaimData,
        backgroundColor: clientThemes.secondary,
        barPercentage: .6,
        categoryPercentage: .5,
      },
      {
        label: 'In-App Redemption ' + metricAlignment,
        data: reactChartRedemptionData,
        backgroundColor: clientThemes.primary,
        barPercentage: .6,
        categoryPercentage: .5
      },
      {
        label: 'Partner Redemption ' + metricAlignment,
        data: reactChartPartnersideData,
        backgroundColor: clientThemes.quaternary,
        barPercentage: .6,
        categoryPercentage: .5

      },
 
    ],
  };


  const options = {
    plugins: {
      legend: {
        labels: {
          color: defaultText,
          font: {
            size: 12,
            weight: 500,
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val);
          },
          color: defaultText,
          beginAtZero: true,
          font: {
            size: 12,
            weight: 500,
          },
        }
      },
      y: {
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val)
          },
          color: defaultText,
          font: {
            size: 12,
            weight: 500,
          }
        }
      }
    }
  };


  // const tableData = resultData?.tableData.map((el) => {
  //   return (
  //     {
  //       ...el,
  //       redemptions_mean: (el?.redemptions_mean * 100).toFixed(2) + '%',
  //       redemptions_lower: (el?.redemptions_lower * 100).toFixed(2) + '%',
  //       redemptions_upper: (el?.redemptions_upper * 100).toFixed(2) + '%',
  //       claims_mean: (el?.claims_mean * 100).toFixed(2) + '%',
  //       claims_lower: (el?.claims_lower * 100).toFixed(2) + '%',
  //       claims_upper: (el?.claims_upper * 100).toFixed(2) + '%',
  //     }
  //   )
  // })

  
  const tableData = tidy(resultData?.tableData,
    mutate({
      redemptions_mean: d => (d?.redemptions_mean * 100).toFixed(2) + '%',
      redemptions_lower: d => (d?.redemptions_lower * 100).toFixed(2) + '%',
      redemptions_upper: d => (d?.redemptions_upper * 100).toFixed(2) + '%',
      claims_mean: d => (d?.claims_mean * 100).toFixed(2) + '%',
      claims_lower: d => (d?.claims_lower * 100).toFixed(2) + '%',
      claims_upper: d => (d?.claims_upper * 100).toFixed(2) + '%',
    }),
    mutateWithSummary({
      partnerside_mean: items => items.map(d => vmax(d, 'partnerside_mean', 'redemptions_mean')),
      partnerside_mean_volume: items => items.map(d => vmax(d, 'partnerside_mean_volume', 'redemptions_mean_volume')),
      partnerside_upper: items => items.map(d => vmax(d, 'partnerside_upper', 'redemptions_upper')),
      partnerside_upper_volume: items => items.map(d => vmax(d, 'partnerside_upper_volume', 'redemptions_upper_volume')),
      partnerside_lower: items => items.map(d => vmax(d, 'partnerside_lower', 'redemptions_lower')),
      partnerside_lower_volume: items => items.map(d => vmax(d, 'partnerside_lower_volume', 'redemptions_lower_volume')),
    })
    )



  const partnerColumns = [
        {
            accessorKey: 'id',
            header: () => <span>Scenario ID</span>,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'partner',
            header: () => <span>Partner</span>,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'date',
            header: () => <span>Day</span>,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'category',
            header: () => <span>Category</span>,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'subcategory',
            header: () => <span>Subcategory</span>,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'offer_construct',
            header: () => <span>Offer Construct</span>,
            cell: info => info.getValue(),
        },
        {
          accessorKey: 'claims_mean_volume',
          header: () => <span>Save Volume</span>,
          aggregationFn: 'sum',
      },
      {
          accessorKey: 'claims_lower_volume',
          header: () => <span>Save Volume (Lower CI)</span>,
          aggregationFn: 'sum',
      },
      {
          accessorKey: 'claims_upper_volume',
          header: () => <span>Save Volume (Upper CI)</span>,
          aggregationFn: 'sum',
      },
        {
            accessorKey: 'redemptions_mean_volume',
            header: () => <span>In-App Redeem Volume</span>,
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'redemptions_lower_volume',
            header: () => <span>In-App Redeem Volume (Lower CI)</span>,
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'redemptions_upper_volume',
            header: () => <span>In-App Redeem Volume (Upper CI)</span>,
            aggregationFn: 'sum',
        },
        {
          accessorKey: 'partnerside_mean_volume',
          header: () => <span>Partner Redeem Volume</span>,
          aggregationFn: 'sum',
      },
      {
          accessorKey: 'partnerside_lower_volume',
          header: () => <span>Partner Redeem Volume (Lower CI)</span>,
          aggregationFn: 'sum',
      },
      {
          accessorKey: 'partnerside_upper_volume',
          header: () => <span>Partner Redeem Volume (Upper CI)</span>,
          aggregationFn: 'sum',
      },

    ]



  return (
    <PadBox padding={["md", "md"]}>
      <Stack gutter="md" style={{ color: "#3c3c3c",}}>
        <AnimatedToggle tabs={toggleButtons} setSelected={setMetricAlignment} selected={metricAlignment}/>

        <HeaderAndMetricLayout infoData={infoData} metricType={metricAlignment} />

        <Stack gutter="xs">
          <div className="text-lg select-none">Projections: Tuesday Averages</div>
          <PadBox padding="lg" >
            <Bar data={data} options={options} style={{ maxHeight: "400px"}}/>
          </PadBox>
        </Stack>

        <Stack gutter="xs">
          <div className="text-lg select-none">Projections: Export Table</div>
          <PadBox padding="lg">
            {!resultData?.tableData || resultData?.tableData.length < 1 ? (
              <PageSpinner />
            ) : (
              <ExpandingTable data={tableData} exportData={tableData} columnDefs={partnerColumns} />
            )
            }
          </PadBox>
        </Stack>


      </Stack>
    </PadBox>
  )
}

function GenerateColumnDefs(metricType) {

  // claims
  const claims_col = metricType === "Volume" ? "claims_mean_volume" : "claims_mean"
  const claims_col_lower = metricType === "Volume" ? "claims_lower_volume" : "claims_lower"
  const claims_col_upper = metricType === "Volume" ? "claims_upper_volume" : "claims_upper"
  // redemptions
  const redemptions_col = metricType === "Volume" ? "redemptions_mean_volume" : "redemptions_mean"
  const redemptions_col_lower = metricType === "Volume" ? "redemptions_lower_volume" : "redemptions_lower"
  const redemptions_col_upper = metricType === "Volume" ? "redemptions_upper_volume" : "redemptions_upper"

  const columnDefs = [
    { title: 'Partner', field: 'partner', defaultGroupOrder: 0 },
    { title: 'Day', field: 'date' },
    { title: 'Category', field: 'category' },
    { title: 'SubCategory', field: 'subcategory' },
    { title: 'Offer Construct', field: 'offer_construct' },
    { title: 'Redemption ' + metricType, field: redemptions_col, type: 'numeric' },
    { title: 'Redemption ' + metricType + ' (Lower CI)', field: redemptions_col_lower, type: 'numeric' },
    { title: 'Redemption ' + metricType + ' (Upper CI)', field: redemptions_col_upper, type: 'numeric' },
    { title: 'Save ' + metricType, field: claims_col, type: 'numeric' },
    { title: 'Save ' + metricType + ' (Lower CI)', field: claims_col_lower, type: 'numeric' },
    { title: 'Save ' + metricType + ' (Upper CI)', field: claims_col_upper, type: 'numeric' },
  ]

  return (columnDefs)
}

function HeaderAndMetric({header, metric}) {
  return (
    <>
      <motion.div style={{ height: '50px' }}>
        <motion.div style={{ fontSize: '1.5rem', fontWeight: 100, textAlign: 'center' }}>
          <h2> {typeof metric === "number" ? metric.toFixed(3) : metric}</h2>
        </motion.div>
        <motion.div style={{ fontSize: '.8rem', fontWeight: 100, textAlign: 'center' }}>
          <h2> {header.toUpperCase()}</h2>
        </motion.div>
      </motion.div>
    </>

  )
}


function HeaderAndMetricLayout({ infoData, metricType }) {

  const claims_key = metricType === "Volume" ? "avg_claims_volume" : "avg_claims"
  const redemptions_key = metricType === "Volume" ? "avg_redemptions_volume" : "avg_redemptions"
  const partnerside_key = metricType === "Volume" ? "avg_partnerside_volume" : "avg_partnerside"


  if (infoData?.[claims_key] === null || infoData?.[redemptions_key] === null) {
    return (<>Error.</>)
  }

  const formatSI = format(".2s")
  const formatP = format(".2%")

  let claims_number = infoData?.[claims_key];
  let redemptions_number = infoData?.[redemptions_key];
  let partnerside_number = infoData?.[partnerside_key];

  if (metricType === "Volume") {
    claims_number = formatSI(claims_number)
    redemptions_number = formatSI(redemptions_number)
    partnerside_number = formatSI(partnerside_number)
  } else {
    claims_number = formatP(claims_number)
    redemptions_number = formatP(redemptions_number)
    partnerside_number = formatP(partnerside_number)
  }
  // const claims_number = metricType === "Volume" ? infoData?.[claims_key].toFixed(0) : ((infoData?.[claims_key] * 100).toFixed(2) + "%")
  // const redemptions_number = metricType === "Volume" ? infoData?.[redemptions_key].toFixed(0) : ((infoData?.[redemptions_key] * 100).toFixed(2) + "%")


  return (
    <Stack gutter="lg">
      <Grid gutter="xl" minItemWidth="200px">
        <HeaderAndMetric header='# PARTNERS' metric={infoData?.n_partners.toFixed(0)} />
        <HeaderAndMetric header="DATE RANGE" metric={infoData?.date_range} />
      </Grid>
      <Grid gutter="xl" minItemWidth="200px" >
        <HeaderAndMetric header={`SAVE ${metricType}*`} metric={claims_number} />
        <HeaderAndMetric header={`IN-APP REDEEM ${metricType}*`} metric={redemptions_number} />
        <HeaderAndMetric header={`PARTNER REDEEM ${metricType}*`} metric={partnerside_number} />
      </Grid>
      <div style={{ fontSize: ".6rem", fontWeight: 500, display: "flex", justifyContent: "end"}}>
        <p>*TUES. AVG.</p>
      </div>
      <hr/>
    </Stack>

  )

}

HeaderAndMetricLayout.propTypes = {
  infoData: PropTypes.exact({
    avg_claims: PropTypes.number,
    avg_claims_volume: PropTypes.number,
    avg_redemptions: PropTypes.number,
    avg_redemptions_volume: PropTypes.number,
    avg_partnerside: PropTypes.number,
    avg_partnerside_volume: PropTypes.number,
    date_range: PropTypes.string,
    n_partners: PropTypes.number,
  }),
  metricType: PropTypes.string
}