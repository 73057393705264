import { Link, useParams } from "react-router-dom";
import { FaCloudUploadAlt, FaWindowClose } from "react-icons/fa";
import { TextInput } from "../../components/shared/Inputs";

import { motion } from "framer-motion";
import { IconButton } from "../../components/shared/Buttons";
import { formBg } from "../../constants";
import { Stack } from "@bedrock-layout/stack";

export default function ScenarioForm({
  formState = {},
  handleSubmit,
  handleCreate,
  handleCancel,
  edit = false,
  url
}) {
  const { state = {}, handleChange } = formState;
  const { groupID } = useParams()

  const { name = "" } = state;

  const handleKeyPress = e => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      edit ? handleSubmit() : handleCreate();
    }
  };

  return (
    <motion.div className="m-4 p-16 text-black text-md flex flex-col w-100 items-start rounded-md"
      style={{
        backgroundColor: formBg,
      }}
    >
      <motion.div className="text-lg text-white pb-4">
        <h2>{edit ? "Edit" : "New"} Calendar </h2>
      </motion.div>
      <motion.div>
        <TextInput
          label="Calendar Name"
          name="name"
          value={name}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </motion.div>

      <Stack gutter="sm">
      <Link
          onClick={() => {
            handleCancel() 
          }}
        >
          <IconButton 
            label="Cancel" 
            icon={
            <FaWindowClose />
          } 
          />

        </Link>
        {/* <div>
          <IconButton label="Cancel" onClick={handleCancel}>
            <FaWindowClose />
          </IconButton>
        </div> */}
        <div>

          <IconButton label="Save" onClick={edit ? handleSubmit : handleCreate}>
            <FaCloudUploadAlt />
          </IconButton>
        </div>
      </Stack>
    </motion.div>
  );
}
