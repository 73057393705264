
import { createContext } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useModalReducer } from '../Modals/Modal';
import { ScenarioGroupNewModal } from './ScenarioGroupNew';
import { ScenarioGroupEditModal } from './ScenarioGroupEdit';


export const SGModalContext = createContext();


export default function ScenarioGroupModalProvider({ children }) {

  const [modalStateEdit, handleModalEdit]  = useModalReducer(false, { relative: "path"})
  const [modalStateNew, handleModalNew]  = useModalReducer(false, { relative: "path"})

  const value = {
    handleModalEdit,
    handleModalNew,
  }


  return (

    <>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalStateEdit.open && <ScenarioGroupEditModal handleModal={handleModalEdit} />}
        {modalStateNew.open && <ScenarioGroupNewModal handleModal={handleModalNew} />}
      </AnimatePresence>
      <SGModalContext.Provider value={value}>
        {children}
      </SGModalContext.Provider>

    </>
  )

}