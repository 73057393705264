import { FaSpinner } from "react-icons/fa";
import { motion } from "framer-motion";
import { useLoading,
  Audio,
  BallTriangle,
  Bars,
  Circles,
  Grid,
  Hearts,
  Oval,
  Puff,
  Rings,
  SpinningCircles,
  TailSpin,
  ThreeDots,
} from '@agney/react-loading';
import { Center } from './Center';

// export function Spinner({size=78, ...props}) {
//   return (
//     <motion.span {...props}>
//       <FaSpinner size={size} />
//     </motion.span>
//   );
// }

export function Spinner({ width = "100", height = "100", ...props }) {
  const { indicatorEl } = useLoading({
    loading: true,
    indicator: <Grid width={width} height={height} />,
  });
  return (
    <motion.span layout {...props}>
      {indicatorEl}
    </motion.span>
  );
}

export function PageSpinner ({ width="100", height="100", ...props}) {
  const { indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width={width} height={height} />,
  });
  return (
    <Center {...props}>
      {indicatorEl}
    </Center>
  );
}

export function DynamicSpinner({ type, wrapper="center", width="100", height="100", $style, ...props}) {
  const components = {
    bars: Bars,
    circles: Circles,
    grid: Grid,
    oval: Oval,
    puff: Puff,
    rings: Rings,
    spinningCircles: SpinningCircles,
    tailSpin: TailSpin,
    threeDots: ThreeDots,
  }
  const Component = components[type]
  const { indicatorEl } = useLoading({
    loading: true,
    indicator: <Component width={width} height={height} style={$style} />,
  });

  const wrappers = {
    center: Center,
    span: motion.span,
  }
  const Wrapper = wrappers[wrapper]
  return (
    <Wrapper {...props}>
      {indicatorEl}
    </Wrapper>
  );
}