// import React, { forwardRef, useMemo } from 'react'
// // import MaterialTable from '@material-table/core';
import CsvDownload from 'react-json-to-csv'

import { CustomReactTable } from "../Tables/ReactTable";





export function ExpandingTable(props) {
  const { data, exportData, columnDefs } = props;

  return (
    <div>
      <CustomReactTable data={data} columnDefs={columnDefs} />
      <CsvDownload 
        style={{
          marginTop: "10px",
          textDecoration: "underline",
        }} 
        data={exportData} />
    </div>
  )
}