import React, { useState, useEffect } from 'react'

import { Bar } from 'react-chartjs-2'
import * as d3 from "d3";
import { ToggleButtons } from '../../shared/ToggleButtons'
import { clientThemes, interpolate } from '../../../constants';


const tuesdayToggleOptions = [
    { value: 'tuesday', label: "Tuesday's Only" },
    { value: 'all', label: 'All' }
]

const monthLabels = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"]


export function myGroupBy(myData, displayName, yKey, alignment) {
    const reduced = myData.reduce(function (m, d, i) {
        const monthYr = monthLabels[d.datetime.getMonth()] + d.datetime.getFullYear()

        if (!m[monthYr]) {
            m[monthYr] = { total: d[yKey], count: 1, displayName: displayName, days: 1 };
            return m;
        }
        m[monthYr].total += d[yKey];
        m[monthYr].count += 1;
        if (d.date !== myData[i - 1].date) {
            m[monthYr].days += 1;
        }
        return m;
    }, {});

    // Create new array from grouped data and compute the average
    const result = Object.keys(reduced).map(function (k) {
        const item = reduced[k];
        return {
            monthYr: k,
            displayName: item.displayName,
            myAvg: item.total / item.days,
        }
    })

    return (result)
}


export function AudienceBar({ forecastData, options, yKey }) {
    const [alignment, setAlignment] = useState('all');
    const [groupedData, setGroupedData] = useState([])
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };


    // const groupedData = forecastData.map((el) => {
    //     if (alignment === "tuesday") {
    //         const tuesdayData = el.data.filter((item) => {
    //             return (
    //                 // ((item.datetime.getDay() === 2 && tuesdayHours.includes(item.datetime.getHours())) ||
    //                 //     (item.datetime.getDay() === 3 && wednesdayHours.includes(item.datetime.getHours())))
    //                 (item.datetime.getDay() === 2)
    //             )
    //         })
    //         console.log("tuesdayData", tuesdayData)
    //         if (tuesdayData === undefined || tuesdayData.length === 0) {
    //             window.alert("No Tuesday data in forecast..")
    //             // setAlignment("all")
    //             console.log("im in here")
    //             return (
    //                 myGroupBy(el.data, el.displayName, yKey, "all")
    //             )
    //         } else {
    //             return (
    //                 myGroupBy(tuesdayData, el.displayName, yKey, alignment)
    //             )
    //         }
    //     } else {
    //         return (
    //             myGroupBy(el.data, el.displayName, yKey, alignment)
    //         )
    //     }
    // })


  useEffect(() => {
    // setBrushExtent(null)
    const newGroupedData = forecastData?.map((el) => {
        if (alignment === "tuesday") {
            const tuesdayData = el.data.filter((item) => {
                return (
                    // ((item.datetime.getDay() === 2 && tuesdayHours.includes(item.datetime.getHours())) ||
                    //     (item.datetime.getDay() === 3 && wednesdayHours.includes(item.datetime.getHours())))
                    (item.datetime.getDay() === 2)
                )
            })
            if (tuesdayData === undefined || tuesdayData.length === 0) {
                window.alert("No Tuesday data in forecast..")
                setAlignment("all")
                return (
                    myGroupBy(el.data, el.displayName, yKey, "all")
                )
            } else {
                return (
                    myGroupBy(el.data, el.displayName, yKey, alignment)
                )
            }
        } else {
            return (
                myGroupBy(el.data, el.displayName, yKey, alignment)
            )
        }
    })
    setGroupedData(newGroupedData)
  }, [alignment])

    const reactChartLabels = groupedData[0]?.map((el) => {
        return (
            el.monthYr
        )
    }).flat(1)



    const colorList = groupedData?.map((item, i) => interpolate(i / groupedData.length))

    const data = {
        labels: reactChartLabels,
        datasets: groupedData?.map((el, i) => {
            return (
                {
                    label: el[0].displayName,
                    data: el.map((item) => item.myAvg),
                    backgroundColor: colorList[i]
                }
            )
        })
    };

    return (
        <>
            <span className="text-md md:text-md ml-4 font-thin select-none">Forecast: Monthly Rollup </span>
            <div className="flex justify-center">
                <ToggleButtons
                    optionLabels={tuesdayToggleOptions}
                    alignment={alignment}
                    handleAlignment={handleAlignment}
                    styles={{ marginLeft: '20px', marginTop: '5px' }}
                /></div>
            <div className={`py-1 my-1 px-1 w-80v m-auto`}>
                <Bar data={data} options={options} />
            </div>
        </>
    )
}