import { createContext } from "react";
import staticData from "../static.json";

export const MetaContext = createContext()

export default function MetaData({ children }) {
  const { metadata: rawMetadata } = staticData;

  const audienceMetadata = rawMetadata.find(o => o?.id === "audience")?.info || []
  const partnershipMetadata = rawMetadata.find(o => o?.id === "partner")?.info || []

  const metadata =
    {
      audienceMetadata,
      partnershipMetadata
    } || {};
  


  return (
    <MetaContext.Provider value={metadata}>
        {children}
    </MetaContext.Provider>
  );
}
