import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { activeBg, materialLabelFont } from '../../constants';

const useStyles = makeStyles({
    root: {
        width: 200,
    },
});

function valuetext(value) {
    return `${value}°C`;
}

export function RangeSlider({
    label,
    value,
    handleChange,
    min,
    max,
    step,
    testid }) {
    const classes = useStyles();

    return (
        <div className={classes.root} data-testid={testid}>
            <Typography id="range-slider" gutterBottom style={{ fontSize: materialLabelFont }}>
                {label}
            </Typography>
            <Slider
                style={{
                    color: activeBg
                }}
                id="rangeslidertestid"
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={min}
                max={max}
                step={step}
                getAriaValueText={valuetext}
            />
        </div>
    );
}