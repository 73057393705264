import { motion } from "framer-motion";
import { PageSpinner } from './Spinners';
import { ContentPanel } from "./TailwindElements";
import { formBg } from '../../constants';



export function FormLoadingScreen() {
  return (
    <ContentPanel>
      <motion.div className="m-4 p-16 text-black text-lg flex flex-col items-start rounded-md"
        style={{
          backgroundColor: formBg,
        }}
      >
        <PageSpinner />
      </motion.div>
    </ContentPanel>
  )
}
