/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAudience = /* GraphQL */ `
  mutation CreateAudience(
    $input: CreateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    createAudience(input: $input, condition: $condition) {
      id
      start_date
      end_date
      geo_type
      group_by
      geo
      msisdn_carrier
      hour_min
      hour_max
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateAudience = /* GraphQL */ `
  mutation UpdateAudience(
    $input: UpdateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    updateAudience(input: $input, condition: $condition) {
      id
      start_date
      end_date
      geo_type
      group_by
      geo
      msisdn_carrier
      hour_min
      hour_max
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteAudience = /* GraphQL */ `
  mutation DeleteAudience(
    $input: DeleteAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    deleteAudience(input: $input, condition: $condition) {
      id
      start_date
      end_date
      geo_type
      group_by
      geo
      msisdn_carrier
      hour_min
      hour_max
      createdAt
      updatedAt
      version
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      editing
      elements {
        items {
          id
          groupID
          start_date
          end_date
          partner
          new_partner
          new_partner_flag
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      result {
        id
        runDate
        displayName
        infoData {
          n_partners
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
          date_range
        }
        barData {
          partner
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
        }
        tableData {
          id
          groupID
          partner
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          createdAt
          updatedAt
          version
          date
          redemptions_mean
          redemptions_lower
          redemptions_upper
          redemptions_mean_volume
          redemptions_lower_volume
          redemptions_upper_volume
          redemptions_label
          redemptions_info
          claims_mean
          claims_lower
          claims_upper
          claims_mean_volume
          claims_lower_volume
          claims_upper_volume
          claims_label
          claims_info
          partnerside_mean
          partnerside_lower
          partnerside_upper
          partnerside_mean_volume
          partnerside_lower_volume
          partnerside_upper_volume
          partnerside_label
          partnerside_info
          prediction
        }
        dateRange {
          min_date
          max_date
        }
        modelIDs {
          claims_model_id
          redemptions_model_id
          partnerside_model_id
        }
      }
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      editing
      elements {
        items {
          id
          groupID
          start_date
          end_date
          partner
          new_partner
          new_partner_flag
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      result {
        id
        runDate
        displayName
        infoData {
          n_partners
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
          date_range
        }
        barData {
          partner
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
        }
        tableData {
          id
          groupID
          partner
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          createdAt
          updatedAt
          version
          date
          redemptions_mean
          redemptions_lower
          redemptions_upper
          redemptions_mean_volume
          redemptions_lower_volume
          redemptions_upper_volume
          redemptions_label
          redemptions_info
          claims_mean
          claims_lower
          claims_upper
          claims_mean_volume
          claims_lower_volume
          claims_upper_volume
          claims_label
          claims_info
          partnerside_mean
          partnerside_lower
          partnerside_upper
          partnerside_mean_volume
          partnerside_lower_volume
          partnerside_upper_volume
          partnerside_label
          partnerside_info
          prediction
        }
        dateRange {
          min_date
          max_date
        }
        modelIDs {
          claims_model_id
          redemptions_model_id
          partnerside_model_id
        }
      }
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      editing
      elements {
        items {
          id
          groupID
          start_date
          end_date
          partner
          new_partner
          new_partner_flag
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      result {
        id
        runDate
        displayName
        infoData {
          n_partners
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
          date_range
        }
        barData {
          partner
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
        }
        tableData {
          id
          groupID
          partner
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          createdAt
          updatedAt
          version
          date
          redemptions_mean
          redemptions_lower
          redemptions_upper
          redemptions_mean_volume
          redemptions_lower_volume
          redemptions_upper_volume
          redemptions_label
          redemptions_info
          claims_mean
          claims_lower
          claims_upper
          claims_mean_volume
          claims_lower_volume
          claims_upper_volume
          claims_label
          claims_info
          partnerside_mean
          partnerside_lower
          partnerside_upper
          partnerside_mean_volume
          partnerside_lower_volume
          partnerside_upper_volume
          partnerside_label
          partnerside_info
          prediction
        }
        dateRange {
          min_date
          max_date
        }
        modelIDs {
          claims_model_id
          redemptions_model_id
          partnerside_model_id
        }
      }
      version
      createdAt
      updatedAt
    }
  }
`;
export const createElement = /* GraphQL */ `
  mutation CreateElement(
    $input: CreateElementInput!
    $condition: ModelElementConditionInput
  ) {
    createElement(input: $input, condition: $condition) {
      id
      groupID
      group {
        id
        name
        editing
        elements {
          nextToken
        }
        result {
          id
          runDate
          displayName
        }
        version
        createdAt
        updatedAt
      }
      start_date
      end_date
      partner
      new_partner
      new_partner_flag
      category
      subcategory
      offer_construct
      createdBy
      editedBy
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateElement = /* GraphQL */ `
  mutation UpdateElement(
    $input: UpdateElementInput!
    $condition: ModelElementConditionInput
  ) {
    updateElement(input: $input, condition: $condition) {
      id
      groupID
      group {
        id
        name
        editing
        elements {
          nextToken
        }
        result {
          id
          runDate
          displayName
        }
        version
        createdAt
        updatedAt
      }
      start_date
      end_date
      partner
      new_partner
      new_partner_flag
      category
      subcategory
      offer_construct
      createdBy
      editedBy
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteElement = /* GraphQL */ `
  mutation DeleteElement(
    $input: DeleteElementInput!
    $condition: ModelElementConditionInput
  ) {
    deleteElement(input: $input, condition: $condition) {
      id
      groupID
      group {
        id
        name
        editing
        elements {
          nextToken
        }
        result {
          id
          runDate
          displayName
        }
        version
        createdAt
        updatedAt
      }
      start_date
      end_date
      partner
      new_partner
      new_partner_flag
      category
      subcategory
      offer_construct
      createdBy
      editedBy
      version
      createdAt
      updatedAt
    }
  }
`;
