import * as d3 from "d3";
import { v4 as uuidv4 } from 'uuid';
import { interpolate } from "../constants";

const parseDate = d3.timeParse("%Y-%m-%d %H:%M:%S")

export function prepLineData(d, dateFilter, jitter=0) {
  const {startDate, endDate} = dateFilter

  const ids = d.map(o => o.id)
  const colorList = ids.map( (item, i) => interpolate(i/ ids.length ))
  const chartData = d.map((item, i) => {
    return {
      id: item.id,
      name: item.displayName,
      color: colorList[i],
      items: item.data
        // .map(o => ({ ...o, x: parseDate(o.x) }))
        // .filter( o => o.x >= startDate )
        // .filter( o => o.x <= endDate )
        .map(o => ({ ...o, y: o.y + d3.randomUniform(jitter)()  }))
    };
  });
  return chartData;
}