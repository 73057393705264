import { useContext, useState } from "react";
import Grid from '@material-ui/core/Grid';
import * as d3 from 'd3';

import {
  AudienceDateInput,
  AudienceMultiInput,
} from "../../components/shared/Inputs";
import { motion } from "framer-motion";
import { IconButton } from "../../components/shared/Buttons";
import { ToggleButtons } from '../../components/shared/ToggleButtons'
import { MetaContext } from "../../contexts/MetaData";
import { RangeSlider } from "../../components/shared/RangeSlider"
import { formBg } from "../../constants";

import dmaLookup from '../../internaldata/dma_lookup.json'
import stateLookup from '../../internaldata/state_lookup.json'


const geoTypeOptionLabels = [
  { value: 'dma', label: 'DMA' },
  { value: 'state', label: 'State' }
]

const groupByOptionLabels = [
  { value: 'total', label: 'Total' },
  { value: 'msisdn_carrier', label: 'Carrier' },
]

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "initial"
  })
};

export default function AudienceForm({
  formData,
  setFormData,
  handleCreate,
  handleRemove,
  submitted,
  setSubmitted
}) {
  const { audienceMetadata } = useContext(MetaContext);
  const metadata = [...audienceMetadata];
  const [alignment, setAlignment] = useState('state');
  const [groupByAlignment, setGroupByAlignment] = useState('total');


  const handleAlignment = (event, newAlignment) => {
    if(newAlignment) {
      setAlignment(newAlignment);
      setFormData({
        ...formData,
        geo_type: [newAlignment],
      })
    }
  };

  const handleGroupByAlignment = (event, newAlignment) => {
    if(newAlignment) {
      setGroupByAlignment(newAlignment);
      setFormData({
        ...formData,
        group_by: newAlignment,
      })
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <ToggleButtons
                optionLabels={geoTypeOptionLabels}
                buttonLabel="Geo Type"
                alignment={alignment}
                handleAlignment={handleAlignment}
              />
            </Grid>
            <Grid item>
              <AudienceMultiInput
                key="geo"
                testid={'geoSelect'}
                label={alignment === "dma" ? "DMA" : "State"}
                name="geo"
                value={formData?.geo}
                options={alignment === "dma" ? dmaLookup : stateLookup}
                onChange={(fieldName, newValues) =>
                  setFormData({
                    ...formData,
                    [fieldName]: [...newValues],
                  })
                }
                closeMenuOnSelect={false}
                isDisabled={groupByAlignment === "geo"}
                isMulti={true}
                styles={customStyles}
              />
            </Grid>
            <Grid item>
              <ToggleButtons
                optionLabels={groupByOptionLabels}
                buttonLabel="Group By"
                alignment={groupByAlignment}
                handleAlignment={handleGroupByAlignment}
              />
            </Grid>
            <Grid item>
              <AudienceMultiInput
                key="msisdn_carrier"
                label="Carrier"
                name="msisdn_carrier"
                value={formData?.msisdn_carrier}
                options={metadata.find((o) => o.id === "msisdn_carrier")?.field_options}
                onChange={(fieldName, newValues) =>
                  setFormData({
                    ...formData,
                    [fieldName]: [...newValues],
                  })
                }
                isMulti={true}
                isDisabled={groupByAlignment === "msisdn_carrier"}
              />
            </Grid>
            <Grid item >
              <AudienceDateInput
                key="start_date"
                label="Start Date"
                name="start_date"
                styles={{ display: 'block' }}
                value={formData?.start_date}
                onChange={(e) => {
                  const dateCheck = e.target.value > formData?.end_date
                  if (dateCheck) {
                    setFormData({
                      ...formData,
                      start_date: e.target.value,
                      end_date: e.target.value
                    })
                  } else {
                    setFormData({
                      ...formData,
                      start_date: e.target.value,
                    })
                  }
                }}
              />
            </Grid>
            <Grid item>
              <AudienceDateInput
                id="end_date"
                key="end_date"
                label="End Date"
                name="end_date"
                styles={{ display: 'block' }}
                value={formData?.end_date}
                onChange={(e) => {
                  setFormData({ ...formData, end_date: e.target.value })
                }}
              />
            </Grid>
            <Grid item>
              <RangeSlider
                id="hour_range_slider"
                label="Hours"
                value={[formData?.hour_min, formData?.hour_max]}
                handleChange={(event, newValue) => {
                  setFormData({ ...formData, hour_min: newValue[0], hour_max: newValue[1] })
                }}
                min={0}
                max={23}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br/>

      <Grid container justifyContent="center" spacing={2}>
        <motion.div className="flex flex-col items-center mb-2 mt-4">
          <motion.div className="flex">
            <IconButton label="Run Forecast"
              onClick={() => {
                setSubmitted(true)
                handleCreate(formData)
              }
              }>
              Run Forecast
            </IconButton>
            <IconButton label="Clear Forecast" onClick={handleRemove}>
              Clear Forecast
            </IconButton>
          </motion.div>
        </motion.div>
      </Grid>
      <br />

    </>
  )
}
