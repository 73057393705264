const redirect = process.env?.NODE_ENV === "development" ?
  "http://localhost:3000/" :
  "https://tmtprojection.com/"

export default {
	apiGateway: {
        name: 'tmob-forecast-prod-audience_estimator',
		REGION: 'us-east-1',
		URL: 'https://dv0yanlv47.execute-api.us-east-1.amazonaws.com/prod/tmob-forecast-prod-audience_estimator'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_aMJ3DDrDM',
		APP_CLIENT_ID: '2rfl7nf1b63l4o65l76ter5o9d',
		IDENTITY_POOL_ID: 'us-east-1:fe48c437-c7fd-473b-a079-0c104647ce39'
	},
	graphql: {
		URL: 'https://l5lvtso4e5bqdiazmqmzhevaiq.appsync-api.us-east-1.amazonaws.com/graphql',
		REGION: 'us-east-1',
		AUTHENTICATION_TYPE: 'AMAZON_COGNITO_USER_POOLS'
	},
	oauth: {
		domain: 'tmtprojection-login.auth.us-east-1.amazoncognito.com',
		scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
		redirectSignIn: redirect,
		redirectSignOut: redirect,
		responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
	},
	aws_cloud_logic_custom: [
    {
        "name": "AdminQueries",
        "endpoint": "https://hfioodw5ve.execute-api.us-east-1.amazonaws.com/test",
        "region": "us-east-1"
    }
]
}