import { useEffect, useState } from "react";

export default function useFormState(data) {
  const [state, setState] = useState(data);

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);


  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  function handleSelectMulti(name, newValues) {
    setState({
      ...state,
      [name]: [...newValues],
    })
  }

  function handleSelectSingle(name, newValue) {


    setState({
      ...state,
      [name]: [newValue.value]
    })
  }

  function handleRaw(name, newValue) {


    setState({
      ...state,
      [name]: newValue
    })
  }




  function handleChecked(e) {
    const { name, value, checked } = e.target;
    setState({
      ...state,
      [name]: checked ? true : false,
    });
  }

  return {
    state,
    handleChange,
    handleChecked,
    handleSelectMulti,
    handleRaw,
  };
}