import React from "react";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import "./MyTest.css"
import { materialFont, materialHeight, materialLabelFont } from '../../constants';

export function ToggleButtons({
  optionLabels,
  buttonLabel,
  alignment,
  handleAlignment,
  styles,
  testid }) {
  return (
    <div style={{ ...styles, }} data-testid={"mytestid"}>
      <label style={{ display: 'block', margin: "2px", fontSize: materialLabelFont }}>{buttonLabel}</label>
      <ToggleButtonGroup
        id={"togglebuttongroup"}
        color="primary"
        value={alignment}
        exclusive
        onChange={handleAlignment}
      >
        {optionLabels.map((el, i) => (
          <ToggleButton
            style={{
              height: materialHeight,
              fontSize: materialFont,
              width: "100%",
              whiteSpace: "nowrap"
            }}
            key={i}
            value={el.value}>
            {el.label}
          </ToggleButton>

        ))}
      </ToggleButtonGroup>
    </div>
  );
}