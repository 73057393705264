
import { MainPanel } from "../../components/shared/TailwindElements";
import AudienceWidget from "./AudienceWidget"


export default function AudienceView() {
  return (
      <AudienceWidget />
  );
}
