import * as d3 from "d3";

export const avgDailyParticipants = 13.81746 // log1p(mean(visits))

export const MAX_DATE_RANGE = 500;
export const SIDEBAR_WIDTH = 12; // vw
export const SIDEBAR_MARGIN = 10;
export const TOGGLE_OPEN = 100 - (SIDEBAR_WIDTH + SIDEBAR_MARGIN);
export const TOGGLE_CLOSED = 100 - SIDEBAR_MARGIN;

export const TOPBAR_HEIGHT = "4rem";
export const STICKYBAR_HEIGHT = "2.5rem"


export const buttonVariants = {
  rest: { scale: 1 },
  hover: { scale: 1.2 },
  tap: { scale: 0.9 }
}

export const tabURLs = {
  partners: '/partners',
  audience: '/audience',
}

export const tmsThemes = {
  // lightblue: "#27AAD3",
  lightblue: "#E20074",
  blue: "#1B45AC",
  lightgreen: "#C8DC63",
  green: "#419357",
  lightblue_clear: "rgba(39, 170, 211, .6)",
  blue_clear: "#1B45AC",
  lightgreen_clear: "rgba(200, 220, 99, .6)",
  green_clear: "#419357"
}


export const clientThemes = {
  primary: "#E20074",
  secondary: "#27AAD3",
  tertiary: "#1B45AC",
  quaternary: "#C8DC63",
  quinary: "#419357",
}


export const interpolate = d3.interpolateRgb(clientThemes.primary, clientThemes.secondary)


const white = "#fff"
const darkGrey = "#575757"
const lightGrey = "#999"
const dark = "#3C3C3C"
const bright = tmsThemes.lightblue;


export const materialFont = ".8rem";
export const materialLabelFont = ".9rem";
export const materialHeight = "2.3rem";

export const primaryBg = white
export const secondaryBg = white;
export const formBg = bright;
export const headerBg = dark
export const tabSelectedBg = bright
export const tabText = white

export const panelBorder = bright
export const sidebarBg = dark
export const sidebarLinkBg = "transparent"
export const sidebarHover = bright
export const sidebarLinkBorder = "transparent"

export const lightBg = white;

export const buttonBg = darkGrey
export const buttonText = white
export const buttonHoverBg = bright;
export const buttonHoverText = white;

export const activeBg = bright;
export const inactiveBg = darkGrey;

export const defaultText = "#000"
// export const activeText = "#575757"
export const activeText = "#fff"
export const inactiveText = "rgba(200, 200, 200)"
