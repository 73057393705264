import { useState, useEffect, useCallback, useContext } from "react";
import { useParams, } from "react-router-dom";
import ElementForm from "./ElementForm";
import { addDays, shortISO } from "../../utils/date-wrangler";
import { timeFormat } from "d3";

import { PageSpinner } from "../../components/shared/Spinners";
import { useAddElement } from "../../utils/api-utils";
import { useAtom } from "jotai";
import { rangeAtom, rangeReducer } from "../InteractiveCalendar/CalendarDay";
import { useResetForecast } from "../InteractiveCalendar/useFetchForecast";
import { withModal } from "../Modals/Modal";
import { useReducerAtom } from "jotai/utils";
import { userAtom } from "../../App";
const today = new Date();
const defaultStart = shortISO(today);
const defaultEnd = shortISO(addDays(today, 14));


const initialFormState = {
  start_date: defaultStart,
  end_date: defaultEnd,
  partner: [null],
  new_partner: null,
  new_partner_flag: false,
  category: [null],
  subcategory: [null],
  offer_construct: [null],
};

export default function ElementNew({ handleModal }) {
  const [formData, setFormData] = useState(initialFormState);
  const [submitted, setSubmitted] = useState(false);
  const { groupID } = useParams();
  const [range, dispatchRange] = useReducerAtom(rangeAtom, rangeReducer)


  const formatYMD = timeFormat("%Y-%m-%d")

  useEffect(() => {
    if (range?.start === undefined || range?.end === undefined) {
      console.log("Range undefined, using default dates.")
      return;
    }


    setFormData(prev => {
      return {
        ...prev,
        start_date: formatYMD(range?.start),
        end_date: formatYMD(range?.end)
      }
    })
  }, [range])



  const [username] = useAtom(userAtom)
  const { mutate: addElement, status, error } = useAddElement()
  const { handleResetForecast } = useResetForecast()

  const handleSubmit = useCallback( () => {
    const copyOfData = { ...formData }
    delete copyOfData.createdAt
    delete copyOfData.updatedAt
    delete copyOfData.group

    const inputData = {
      ...copyOfData,
      createdBy: username,
      editedBy: username,
      groupID
    }
    // handleDelete ?
    //   { ...copyOfData, 
    //     editedBy: username, 
    //     expectedVersion: copyOfData.version
    //    } :


    addElement(inputData);
    setSubmitted(true)
  }, [groupID, username, formData, username])

  useEffect(() => {
    if (submitted) {
      handleResetForecast()
      dispatchRange({ type: "reset"})
    }

  }, [submitted]);




  if (status === "loading") {
    return <PageSpinner />;
  }

  if (status === "error") {
    console.log("Error creating new element: ", error)
    return <p>{error.message}</p>
  }




  return (
    <ElementForm
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
      handleModal={handleModal}
    />
  );
}

export const ElementNewModal = withModal(ElementNew);