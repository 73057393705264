export function Center(props) {
  return (
      <div
          style={{
              // width: "100vw",
              // height: "50vh",
              display: "flex",
              placeItems: "center",
              placeContent: "center",
              placeSelf: "center",
              margin: "4rem 1rem",
              
          }}
          {...props}
      >
      </div>
  )
}