import { createRoot } from "react-dom/client";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import RobotoCondensed from "./assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import RobotoCondensedBold from "./assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf";
import RobotoCondensedLight from "./assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf";
import EpilogueMedium from "./assets/fonts/Epilogue/static/Epilogue-Medium.ttf";
import RobotoRegular from "./assets/fonts/Roboto/Roboto-Regular.ttf";
import Roboto from "./assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoThin from "./assets/fonts/Roboto/Roboto-Thin.ttf";
import RobotoLight from "./assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoBold from "./assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBlack from "./assets/fonts/Roboto/Roboto-Black.ttf";
import { createGlobalStyle } from "styled-components";
import App from "./App";
import "./tailwind.css"
import { primaryBg } from './constants';

const GlobalStyle = createGlobalStyle`

  :root {
    --color-primary: #eb2f64;
    --color-primary-light: #FF3366;
    --color-primary-dark: #BA265D;

    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f4f2f2;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;
    
    --color-grey-dark-0: #111;
    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;

    --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

    --line: 1px solid var(--color-grey-light-2);
  }

  $bp-largest: 75em;     // 1200px
  $bp-large: 68.75em;    // 1100px
  $bp-medium: 56.25em;   // 900px
  $bp-small: 37.5em;     // 600px
  $bp-smallest: 31.25em; // 500px




  @font-face {
    font-family: 'Roboto Condensed';
    src: url(${RobotoCondensedLight}) format('truetype');
    font-weight: 100;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto Condensed';
    src: url(${RobotoCondensed}) format('truetype');
    font-weight: 400;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto Condensed';
    src: url(${RobotoCondensedBold}) format('truetype');
    font-weight: 700;
    font-display: block; 
  }

  @font-face {
    font-family: 'EpilogueMedium';
    src: url(${EpilogueMedium}) format('truetype');
    font-weight: 400;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoThin}) format('truetype');
    font-weight: 100;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLight}) format('truetype');
    font-weight: 300;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoRegular}) format('truetype');
    font-weight: 400;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto}) format('truetype');
    font-weight: 500;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold}) format('truetype');
    font-weight: 700;
    font-display: block; 
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBlack}) format('truetype');
    font-weight: 900;
    font-display: block; 
  }





  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
  } 

  body {
    background: ${primaryBg};
    // min-height: 100%;
    // width: 100%;
  }

`;



const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);
