import React from 'react';
import { useState, useLayoutEffect, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { ScenarioGroupOutput } from "./ScenarioGroupOutput";

import { ToggleButtons } from '../../components/shared/ToggleButtons'
import { useDeleteGroup, useGroup, useGroups } from "../../utils/api-utils";

import { atom, useAtom } from "jotai";
import SidePanel from '../../components/SidePanel';
import InteractiveCalendar from '../InteractiveCalendar/InteractiveCalendar';
import ElementModalProvider from './ElementModalProvider';
import styled from 'styled-components';


const toggleOptionLabels = [
  { value: 'calendar', label: 'Calendar' },
  { value: 'projections', label: 'Projections' }
]



export const groupsAtom = atom([]);

export const lastGroupAtom = atom((get) => {
  const groups = get(groupsAtom);
  const lastId = groups[Math.max(groups.length - 1, 0)]?.id;
  return lastId
})

export const groupIDAtom = atom('none');
export const groupAtom = atom({})


export default function ScenarioGroupsView() {
  const [groups, setGroups] = useAtom(groupsAtom);
  const [groupID, setGroupID] = useAtom(groupIDAtom);
  const [group, setGroup] = useAtom(groupAtom);
  const [lastId, setLastId] = useAtom(lastGroupAtom);

  const { data: groupsData = [] } = useGroups();


  useEffect(() => {
    if (groupsData.length > 0) {
      setGroups(groupsData);
    }
  }, [groupsData, setGroups]);



  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();


  useEffect(() => {
    const re = new RegExp("undefined")
    const { pathname = "" } = location;

    if (pathname === "/" || pathname.search(re) > -1) {
      navigate("/partners")
    }

    if (pathname === "/partners" && lastId) {
      console.log(`Navigating to default group: ${lastId}`)
      navigate(`/partners/${lastId}`)
    }

  }, [location, lastId, navigate])

  useEffect(() => {
    const { groupID } = params;
    setGroupID(groupID);
  }, [params, setGroupID])

  const { data: groupData, isFetching, isLoading: groupIsLoading } = useGroup(groupID);

  useEffect(() => {
    if (groupData) {
      setGroup(groupData)
    }
  }, [groupID, groupData, setGroup]);


  const [alignment, setAlignment] = useState('calendar');

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };



  const {
    mutate: deleteGroup,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useDeleteGroup();

  const [isOpen, setIsOpen] = useState(false);



  const resultData = groupData?.result ? groupData?.result[0] : []
  const infoData = resultData?.infoData ? resultData.infoData[0] : []


  return (
   


      <motion.div
        className="flex flex-col"
        style={{
          gap: "10px",
        }}
      >
        <SidePanel />
        <motion.div className="h-auto overflow-auto select-none">
        <ElementModalProvider>
          <InteractiveCalendar currentGroup={groupData} />
        </ElementModalProvider>
    
        </motion.div>
      </motion.div>
  );
}

