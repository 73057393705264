import React from 'react';
import { motion } from "framer-motion";

import { AudienceBar } from '../../components/D3Charts/Chart/AudienceBar';
import { TimelineChart } from '../../components/D3Charts/Timeline/TimelineChart';
import { ExpandingTable } from "../../components/Charts/ExpandingTable";
import { defaultText } from '../../constants';

const options = {
  plugins: {
    legend: {
      labels: {
        color: defaultText,
        font: {
          size: 12,
          weight: 500,
        }
      }
    }
  },
  scales: {
    x: {
      ticks: {
        callback: function(val, index) {
          return this.getLabelForValue(val);
        },
        color: defaultText,
        beginAtZero: true,
        font: {
          size: 12,
          weight: 500,
        }
      }
    },
    y: {
      ticks: {
        callback: function(val, index) {
          return this.getLabelForValue(val)
        },
        color: defaultText,
        font: {
          size: 12,
          weight: 500,
        }
      }
    }
  }
};



export const AudienceOutput = React.memo(({ fetching,
    tableData,
    forecastData,
    dateFilter,
    columnDefs,
    chartVariants }) => {


    const exportData = forecastData.map( o => o.data).flat()
      .map(o => {
        const newObj = {...o}
        delete newObj.datetime
        return newObj
      })

    
    return (
        <motion.div>
            <div className="mt-4">
                <span className="text-md md:text-md ml-4 font-thin select-none">Forecast: Trend View</span>

                <motion.div
                    className={`py-1 my-1 px-1 w-80v m-auto`}
                    initial="off"
                    animate="on"
                    variants={chartVariants}
                    exit="off"
                >
                    <TimelineChart
                        data={forecastData}
                        dateFilter={dateFilter}
                    />
                </motion.div>
            </div>
            <div className="mt-8">
                <AudienceBar forecastData={forecastData} options={options} yKey="mean"/>
            </div>
            <div className="mt-8">
                <span className="text-md md:text-md ml-4 font-thin select-none">Forecast: Detail</span>
                <div
                    className={`py-1 my-4 px-1 w-80v m-auto`}
                >
                    <ExpandingTable data={tableData} exportData={exportData} columnDefs={columnDefs} />
                </div>
            </div>
        </motion.div>
    )
}, (prevProps, nextProps) => {
    if (prevProps.forecastData !== nextProps.forecastData) {
        return true
    }
    return false
})