import { useState, useEffect, useContext, useCallback } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import * as d3 from "d3"
import { v4 as uuidv4 } from 'uuid';
import AudienceForm from "./AudienceForm";
import { AudienceOutput } from "./AudienceOutput";
import { useCreateAudience } from "../../utils/api-utils";
import { addDays, shortISO } from "../../utils/date-wrangler";
import { rAudienceURL, apiKey } from '../../api-constants';
import { useSessionStorage } from "../../hooks/useSessionStorage"
import { PageSpinner, Spinner } from "../../components/shared/Spinners";
import WarmupContext from '../../contexts/Warmup';

import { Auth } from "aws-amplify";
import { useAtom } from "jotai";
import { audienceReadyAtom } from "../../App";

const today = new Date();
// const defaultStart = shortISO(today);
// const defaultStart  = shortISO(d3.timeParse("%m/%d/%Y")("04/01/2023"));
const defaultStart  = shortISO(today);
const defaultEnd = shortISO(addDays(today, 14));


const initialFormState = {
  start_date: defaultStart,
  end_date: defaultEnd,
  geo_type: "state",
  group_by: "total",
  geo: ["<aggregated>"],
  msisdn_carrier: ["<aggregated>"],
  hour_min: 0,
  hour_max: 23
};

const columnDefs = [
  { title: 'Date', field: 'day' },
  { title: 'Name', field: 'displayName', defaultGroupOrder: 0 },
  { title: 'State', field: 'state' },
  { title: 'Carrier', field: 'msisdn_carrier' },
  { title: 'Unique Daily Visitors', field: 'y', type: 'numeric' },
  { title: 'Lower 80% CI', field: 'lower', type: 'numeric' },
  { title: 'Upper 80% CI', field: 'upper', type: 'numeric' },
]


const audienceColumns = [
      {
          accessorKey: 'day',
          header: () => <span>Date</span>,
          cell: info => info.getValue(),
      },
      {
          accessorKey: 'displayName',
          header: () => <span>Name</span>,
          cell: info => info.getValue(),
      },
      {
          accessorKey: 'geo_type',
          header: () => <span>Geo Type</span>,
          cell: info => info.getValue(),
      },
      {
          accessorKey: 'msisdn_carrier',
          header: () => <span>Carrier</span>,
          cell: info => info.getValue(),
      },
    
      {
          accessorKey: 'y',
          header: () => <span>Unique Daily Visitors</span>,
          aggregationFn: 'sum',
      },
      {
          accessorKey: 'lower',
          header: () => <span>Lower 80% CI</span>,
          aggregationFn: 'sum',
      },
      {
          accessorKey: 'upper',
          header: () => <span>Upper 80% CI</span>,
          aggregationFn: 'sum',
      },
  

  ]



const chartVariants = {
  on: {
    opacity: 1,
  },
  off: {
    opacity: 0,
  }
}




export default function AudienceWidget() {

  const [formData, setFormData] = useSessionStorage('formData', initialFormState);
  const [submitted, setSubmitted] = useSessionStorage('submitted', false);
  const [fetching, setFetching] = useState(false);
  const [results, setResults] = useSessionStorage('results', [])

  // const { audienceReady } = useContext(WarmupContext)
  const [ audienceReady ] = useAtom(audienceReadyAtom)

  useEffect(() => {
    console.log("Audience widget ready: ", audienceReady)
  }, [audienceReady])


  const { createAudience } = useCreateAudience()
  const parseDate = d3.timeParse("%Y-%m-%d %H:%M:%S")


  function checkInput(input) {
    if (
      input.start_date === "" ||
      input.end_date === "" ||
      input.geo.length === 0 ||
      input.msisdn_carrier.length === 0
    ) {
      window.alert("Input data contains null fields. Supply value to all inputs to continue with forecast.")
      handleClear()
      return(false)
    } else { return(true) }
  }


  async function fetchRData(input) {

    if(!audienceReady) {
      console.log("Audience API not ready. Exiting.")
      return
    }

    const inputFlag = checkInput(input)
    if (!inputFlag) { 
      console.log("Input check failed, exiting fetch.")
      return 
    }

    console.log("Fetching audience data...")
    setFetching(true)


    await axios({
      method: 'post',
      url: rAudienceURL,
      // data: { body: {req: input} },
      data: {req: input},
      headers: {
        'X-Api-Key': apiKey,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })
      .then((resp) => {
        console.log("Audience Widget Response", resp.data)
        const validResponse = checkResponse(resp.data)
        const errorType = resp.data?.errorType
        if(validResponse) {
          setResults(resp.data)
        } else if (errorType === 'Function.ResponseSizeTooLarge') {
          window.alert("Data limit exceeded. Please choose fewer Geo options or reduce date range.")
          handleClear();
        } else {
          window.alert("Error fetching. Check inputs or please reach out for support.")
          handleClear();
        }

      })
      .catch(error => {
        console.log("Error fetching R Data: ", error)
      })
      .finally(() => {
        setFetching(false)
      })
  }

  function checkResponse(data) {
    if(!Array.isArray(data)) {
      return false
    }
    if(!data[0].data) {
      return false
    }
    return true
  }

  const handleFetch = async () => {
    if (fetching) {
      console.log("Fetch already in progress. Exit.")
      return;
    }
    await fetchRData(formData)
  }

  function handleCreate() {
    formData["id"] = uuidv4()
    const newInput = { ...formData }
    createAudience(newInput);
    handleFetch()
  }

  const handleClear = useCallback((e) => {
     setFetching(false);
     setResults([]) 
     setFormData(initialFormState);
    }, [setResults, setFetching, setFormData])

  function handleRemove() {
    if (window.confirm("Are you sure you want to clear the current forecast?")) {
      console.log("Clearing results...")
      handleClear()
      setSubmitted(false)
    }
  }

  const dateKey = "datetime"
  const dateRange = results[0]?.data?.map(o => ({ datetime: parseDate(o?.[dateKey]) })) || []

  const startDate = d3.min(dateRange, o => o[dateKey])
  const endDate = d3.max(dateRange, o => o[dateKey])


  const dateFilter = {
    startDate,
    endDate
  }

  const forecastData = results.map((el) => {
    return (
      {
        ...el,
        data: el.data.map(d => ({ ...d, datetime: parseDate(d[dateKey]) }))
      }
    )
  }) || [];



  const tableData = forecastData.map((el) => {
    return (
      el?.tableRowData.map((row) => {
        return (
          {
            ...row,
            y: Math.round(row.y),
            lower: Math.round(row.lower),
            upper: Math.round(row.upper),
            displayName: el.displayName
          }
        )
      })
    )
  }).flat(1) || [];

  

  return (
    <motion.div 
      className="select-none p-4" 
      style={{ position: "sticky", 
      top: 0, left:0, marginTop: "4rem"}}>
      <AudienceForm
        formData={formData}
        setFormData={setFormData}
        handleCreate={handleCreate}
        handleRemove={handleRemove}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
      {
        audienceReady === false ?
          <div className="flex justify-center font-bold mt-12 text-mg">
            <span className="mr-4">API warming up. This may take 30-60 seconds. Please wait. </span>
            <Spinner width="20" height="20" />
          </div> :
          fetching ?
            <PageSpinner /> :
            (!forecastData || forecastData.length < 1) ?
              <div className="flex justify-center font-bold mt-12 text-md">
                No data to display. Please run forecast to view output.
              </div> :
              (<AudienceOutput
                fetching={fetching}
                tableData={tableData}
                forecastData={forecastData}
                dateFilter={dateFilter}
                columnDefs={audienceColumns}
                chartVariants={chartVariants}
              />
              )
      }

    </motion.div>
  );
}