import React, { useMemo } from "react"
import Select, { components as RSComponents } from "react-select";
import TextField from "@material-ui/core/TextField";
import { materialFont, materialHeight, materialLabelFont } from '../../constants';
import { motion } from "framer-motion"

export function MultiInput({
  label,
  name,
  value = [],
  options,
  onChange,
  isMulti,
  isDisabled,
  defaultValue,
  styles,
}) {
  let selectedOptions;
  if (value instanceof Array) {
    selectedOptions = options.filter((o) => value.includes(o.value));
  } else {
    selectedOptions = options.filter((o) => o.value === value?.value);
  }

  function unpackValues(values) {
    if (values instanceof Array) {
      return values.map((v) => v.value);
    } else {
      return values;
    }
  }
  return (
    <div
      className="flex-grow"
      style={{
        display: "flex",
        minWidth: 400,
        justifyContent: "start",
        alignItems: "center",
        marginBottom: "1rem",
        ...styles
      }}
    >
      <label style={{ width: "12rem" }} htmlFor={name}>{label}</label>
      <Select
        className="flex-grow h-12 text-deep-gray-dark"
        isMulti={isMulti}
        name={name}
        value={selectedOptions}
        onChange={(newValues) => onChange(name, unpackValues(newValues))}
        options={options}
        styles={styles}
        classNamePrefix="react-select"
        menuPlacement="auto"
        isDisabled={isDisabled}
        maxMenuHeight={180}
        defaultValue={defaultValue}
        data-testid="multi-input-id"
      />
    </div>
  );
}



const ValueContainer = ({ selectProps, children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    values = selectProps.value.map((x) => x.label).join(', ');
  }

  return (
    <RSComponents.ValueContainer {...props}>
      <div style={{
        maxWidth: "80%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}>
        {values}
       </div>
      {input}
    </RSComponents.ValueContainer>
  );
};


export function AudienceMultiInput({
  label,
  name,
  value = [],
  options,
  onChange,
  isMulti,
  isDisabled,
  defaultValue,
  closeMenuOnSelect,
  testid,
  styles
}) {
  let selectedOptions;
  if (value instanceof Array) {
    selectedOptions = options.filter((o) => value.includes(o.value));
  } else {
    selectedOptions = options.filter((o) => o.value === value?.value);
  }

  function unpackValues(values) {
    if (values instanceof Array) {
      return values.map((v) => v.value);
    } else {
      return values;
    }
  }

  const customStyles = useMemo(() => ({
    control: base => ({
      ...base,
      height: materialHeight,
      fontSize: materialFont,
      minHeight: 35,
      width: 150,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        whiteSpace: "nowrap",
        overflow: "hidden",
        flexWrap: 'nowrap',
        // justifyContent: "center",
        // alignItems: "center",
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    }),
  }), []);

  return (
    <div>
      <label
        style={{
          display: "block",
          margin: "2px",
          fontSize: materialLabelFont
        }}
        htmlFor={name}>{label}</label>
      <Select
        className="text-deep-gray-dark"
        data-testid={testid}
        isMulti={isMulti}
        name={name}
        value={selectedOptions}
        onChange={(newValues) => onChange(name, unpackValues(newValues))}
        options={options}
        components={{ ValueContainer }}
        styles={customStyles}
        hideSelectedOptions={false}
        closeMenuOnSelect={closeMenuOnSelect}
        classNamePrefix="react-select"
        menuPlacement="auto"
        isDisabled={isDisabled}
        maxMenuHeight={180}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export function TextInput({ label, name, value, onChange, ...props }) {
  return (
    <motion.div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <label style={{ width: "12rem" }} htmlFor={name}>{label}</label>
      <TextField
        disabled={props.disabled}
        name={name}
        value={value}
        onChange={onChange}
        style={{ width: "20rem" }}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        autoComplete="off"
        {...props}
      />
    </motion.div>
  );
}


export function DateInput({ label, name, value, onChange, styles, testid }) {
  return (
    <div
    className="flex justify-start items-center mb-4"
      style={{
        // display: "flex",
        // justifyContent: "start",
        // alignItems: "center",
        // marginBottom: "1rem",
        ...styles
      }}
      data-testid={testid}
    >
      <label style={{ width: "12rem", display:"block" }} htmlFor={name}>{label}</label>
      <input
        type="date"
        className="text-deep-gray-dark rounded px-4"
        // style={{ width: "12rem", fontFamily: "Roboto", fontSize: "16px" }}
        value={value}
        name={name}
        onChange={onChange}
      ></input>
    </div>
  );
}

export function AudienceDateInput({ label, name, value, onChange, styles, testid }) {
  return (
    <div
      className="flex justify-start items-center w-full"
      style={{
        height: materialHeight,
        ...styles
      }}
      data-testid={testid}
    >
      <label style={{ display:"block", margin: "2px", fontSize: materialLabelFont }} htmlFor={name}>{label}</label>
      <input
        type="date"
        className="w-full h-full text-deep-gray-dark rounded px-2"
        style={{
          fontSize: materialFont,
        }}
        value={value}
        name={name}
        onChange={onChange}
      ></input>
    </div>
  );
}