/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAudience = /* GraphQL */ `
  query GetAudience($id: ID!) {
    getAudience(id: $id) {
      id
      start_date
      end_date
      geo_type
      group_by
      geo
      msisdn_carrier
      hour_min
      hour_max
      createdAt
      updatedAt
      version
    }
  }
`;
export const listAudiences = /* GraphQL */ `
  query ListAudiences(
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start_date
        end_date
        geo_type
        group_by
        geo
        msisdn_carrier
        hour_min
        hour_max
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      editing
      elements {
        items {
          id
          groupID
          start_date
          end_date
          partner
          new_partner
          new_partner_flag
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      result {
        id
        runDate
        displayName
        infoData {
          n_partners
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
          date_range
        }
        barData {
          partner
          avg_redemptions
          avg_claims
          avg_partnerside
          avg_redemptions_volume
          avg_claims_volume
          avg_partnerside_volume
        }
        tableData {
          id
          groupID
          partner
          category
          subcategory
          offer_construct
          createdBy
          editedBy
          createdAt
          updatedAt
          version
          date
          redemptions_mean
          redemptions_lower
          redemptions_upper
          redemptions_mean_volume
          redemptions_lower_volume
          redemptions_upper_volume
          redemptions_label
          redemptions_info
          claims_mean
          claims_lower
          claims_upper
          claims_mean_volume
          claims_lower_volume
          claims_upper_volume
          claims_label
          claims_info
          partnerside_mean
          partnerside_lower
          partnerside_upper
          partnerside_mean_volume
          partnerside_lower_volume
          partnerside_upper_volume
          partnerside_label
          partnerside_info
          prediction
        }
        dateRange {
          min_date
          max_date
        }
        modelIDs {
          claims_model_id
          redemptions_model_id
          partnerside_model_id
        }
      }
      version
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        editing
        elements {
          nextToken
        }
        result {
          id
          runDate
          displayName
        }
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getElement = /* GraphQL */ `
  query GetElement($id: ID!) {
    getElement(id: $id) {
      id
      groupID
      group {
        id
        name
        editing
        elements {
          nextToken
        }
        result {
          id
          runDate
          displayName
        }
        version
        createdAt
        updatedAt
      }
      start_date
      end_date
      partner
      new_partner
      new_partner_flag
      category
      subcategory
      offer_construct
      createdBy
      editedBy
      version
      createdAt
      updatedAt
    }
  }
`;
export const listElements = /* GraphQL */ `
  query ListElements(
    $filter: ModelElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        group {
          id
          name
          editing
          version
          createdAt
          updatedAt
        }
        start_date
        end_date
        partner
        new_partner
        new_partner_flag
        category
        subcategory
        offer_construct
        createdBy
        editedBy
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
