import styled from "styled-components"
import { ImMenu } from 'react-icons/im';
import { tmsThemes, buttonVariants } from '../../constants';
import { useState } from "react";
import { motion } from "framer-motion";
import { useAtom, atom } from 'jotai';
import { ReactPortal } from "../../portals/ReactPortal";




const Wrapper = styled(motion.div)`
  position: absolute;
  top: 2;
  left: 1rem;
  z-index: 999;
  background: rgba(255, 255, 255, .1);
`


// const buttonVariants = {
//   hidden: { opacity: 0, x: -100 },
//   visible: { opacity: 1, x: 0 }
// }

export const listHiddenAtom = atom(true);

export default function FloatingMenuIcon() {


  const [hidden, setHidden] = useAtom(listHiddenAtom);

  return (
    <Wrapper
      variants={buttonVariants}
      initial="rest"
      // animate={hidden ? "visible" : "hidden"}
      // transition={{ bounce: 0.7 }}
      // whileHover={{ scale: 1.2 }}
      whileHover="hover"
      whileTap="tap"
      onClick={() => setHidden(!hidden)}
    >

      <ImMenu
        size={24}
        color="#fff"
      />
    </Wrapper>
  )
}