
import { createContext } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ElementEditModal } from '../ScenarioElements/ElementEdit';
import { useModalReducer } from '../Modals/Modal';
import { ElementNewModal } from '../ScenarioElements/ElementNew';


export const ModalContext = createContext();


export default function ElementModalProvider({ children }) {

  const [modalStateEdit, handleModalEdit]  = useModalReducer(false)
  const [modalStateNew, handleModalNew]  = useModalReducer(false)

  const value = {
    handleModalEdit,
    handleModalNew,
  }


  return (

    <>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalStateEdit.open && <ElementEditModal handleModal={handleModalEdit} />}
        {modalStateNew.open && <ElementNewModal handleModal={handleModalNew} />}
      </AnimatePresence>
      <ModalContext.Provider value={value}>
        {children}
      </ModalContext.Provider>

    </>
  )

}