import { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaCloudUploadAlt, FaWindowClose } from "react-icons/fa";

import {
  TextInput,
  DateInput,
  MultiInput,
} from "../../components/shared/Inputs";
import { motion } from "framer-motion";
import { IconButton } from "../../components/shared/Buttons";
import { MetaContext } from "../../contexts/MetaData";
import { formBg } from "../../constants";
import partnerLookup from '../../internaldata/partner_lookup.json'
import categoryLookup from '../../internaldata/category_lookup.json'
import subcategoryLookup from '../../internaldata/subcategory_lookup.json'
import offerconstructLookup from '../../internaldata/offerconstruct_lookup.json'
import partnerCatSubCatLookup from '../../internaldata/partner_cat_subcat_lookup.json'
import { Stack } from "@bedrock-layout/stack";
import { useAtom } from "jotai";
import { userAtom } from "../../App";
import { rangeAtom, rangeReducer } from "../InteractiveCalendar/CalendarDay";
import { useReducerAtom } from "jotai/utils";


function InfoText({ label, value }) {
  return (
    <p>
      <span className="font-bold">{label}</span>
      <span className="font-light">{value}</span>
    </p>
  )
}

export default function ElementForm({
  formData,
  setFormData,
  handleSubmit,
  handleDelete,
  handleModal,
}) {
  const { audienceMetadata, partnershipMetadata } = useContext(MetaContext);
  const [username] = useAtom(userAtom)
  const [range, dispatchRange] = useReducerAtom(rangeAtom, rangeReducer)
  const metadata = [...audienceMetadata, ...partnershipMetadata];



  const { groupID, elementID } = useParams()

  const currentCat = formData.category === undefined ? "" : formData.category[0]

  const options = partnerCatSubCatLookup.filter((el) => {
    return (
      el.category === currentCat
    )
  })


  const subCatOptions = options.length > 0 ? options.map((innerEl) => innerEl.subcategory) : subcategoryLookup.map((el) => el.value)

  const dynamicSubCategoryLookup = subcategoryLookup.filter((el) => {
    return (subCatOptions.includes(el.value))
  })

  return (
    <motion.div className="m-4 p-16 text-black text-md flex flex-col items-start rounded-md"
      style={{
        backgroundColor: formBg
      }}
    >
      <motion.div className="pb-2">
        <p className="text-lg text-white">
          {handleDelete ? "Edit" : "New"} Calendar Entry
        </p>
      </motion.div>
      <motion.div className={`
          mb-4 p-2 text-sm text-white text-opacity-80
          border-dashed border-white border-2 rounded-md border-opacity-60
          `}>
        <InfoText label="Current User: " value={username || "unknown"} />
        <InfoText label="Created By: " value={formData?.createdBy || "unknown"} />
        <InfoText label="Last Edited By: " value={formData?.editedBy || "unknown"} />
      </motion.div>
      <motion.div>
        <DateInput
          key="start_date"
          label="Start Date"
          name="start_date"
          value={formData?.start_date}
          onChange={(e) => {
            const dateCheck = e.target.value > formData?.end_date

            if (dateCheck) {
              setFormData({
                ...formData,
                start_date: e.target.value,
                end_date: e.target.value
              })
            } else {
              setFormData({
                ...formData,
                start_date: e.target.value,
              })
            }
          }}
        />
        <DateInput
          id="end_date"
          key="end_date"
          label="End Date"
          name="end_date"
          value={formData?.end_date}
          onChange={(e) => {
            setFormData({ ...formData, end_date: e.target.value })
          }}
        />
        <motion.div layout className="flex">
          {
            formData?.new_partner_flag ?
              <TextInput
                label="Partner"
                name="new_partner"
                value={formData?.new_partner}
                onChange={e => {
                  setFormData({
                    ...formData,
                    new_partner: e.target.value,
                    partner: [e.target.value],
                  })
                }}
              /> :
              <MultiInput
                id="partner"
                key="partner"
                label="Partner"
                name="partner"
                value={formData?.partner}
                options={partnerLookup}
                onChange={(fieldName, newValue) =>
                  setFormData({
                    ...formData,
                    category: [null],
                    subcategory: [null],
                    [fieldName]: [newValue.value],
                  })
                }
                isDisabled={formData?.new_partner_flag}
                isMulti={false}
              />
          }
          <motion.div className="flex flex-col items-center ml-2">
            <label className="text-sm" htmlFor="new_partner_checkbox">New Partner</label>
            <input
              type="checkbox"
              id="new_partner_checkbox"
              value={formData?.new_partner_flag}
              checked={formData?.new_partner_flag}
              onChange={() => {
                setFormData({
                  ...formData,
                  partner: [null],
                  category: [null],
                  subcategory: [null],
                  new_partner: null,
                  new_partner_flag: !formData?.new_partner_flag
                })
              }
              }
            />
          </motion.div>
        </motion.div>
        <MultiInput
          key="category"
          label="Category"
          name="category"
          value={formData?.category}
          options={categoryLookup}
          onChange={(fieldName, newValue) =>
            setFormData({
              ...formData,
              subcategory: [null],
              [fieldName]: [newValue.value],
            })
          }
          isMulti={false}
        />
        <MultiInput
          key="subcategory"
          label="Sub-Category"
          name="subcategory"
          value={formData?.subcategory}
          options={dynamicSubCategoryLookup}
          onChange={(fieldName, newValue) =>
            setFormData({
              ...formData,
              [fieldName]: [newValue.value],
            })
          }
          isMulti={false}
        />
        <MultiInput
          key="offer_construct"
          label="Offer Construct"
          name="offer_construct"
          value={formData?.offer_construct}
          options={offerconstructLookup}
          onChange={(fieldName, newValue) =>
            setFormData({
              ...formData,
              [fieldName]: [newValue.value],
            })
          }
          isMulti={false}
        />
      </motion.div>

      <Stack gutter="sm">
        {handleDelete && (
          <div>

            <IconButton
              label="Delete"
              onClick={() => {
                handleDelete({
                  id: formData?.id,
                  version: formData?.version,
                  groupID: formData?.groupID
                })
                handleModal()
              }
              }>
              <FaCloudUploadAlt />
            </IconButton>
          </div>
        )}
        <Link
          onClick={() => {
            handleModal() 
            dispatchRange({ type: "reset" })
          }}
        >
          <IconButton 
            label="Cancel" 
            icon={
            <FaWindowClose />
          } 
          />

        </Link>
        <div>

          <IconButton label="Save"
            icon={<FaCloudUploadAlt />}
            onClick={() => {
              handleSubmit()
              handleModal()
            }}
          />
        </div>
      </Stack>
    </motion.div>
  );
}
