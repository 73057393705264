export function sortByDate(a, b, sortKey) {
  return sortKey 
    ? new Date(a[sortKey]).getTime() - new Date(b[sortKey]).getTime() 
    : new Date(a).getTime() - new Date(b).getTime();
}



export function sortByDateStr(a, b, sortKey, parser) {
  const timeA = sortKey ? parser(a[sortKey]).getTime() : parser(a).getTime()
  const timeB = sortKey ? parser(b[sortKey]).getTime() : parser(b).getTime()

  return timeA - timeB

}


