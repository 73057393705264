
import styled from "styled-components"
import { motion } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5"
import { clientThemes, tabURLs, tmsThemes } from '../constants';
import { NavLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { groupIDAtom, groupsAtom } from "../pages/ScenarioGroups/ScenarioGroupsView";
import { listHiddenAtom } from './FloatingIcon/FloatingMenuIcon';
import { CreateNewGroup } from "../pages/ScenarioGroups/StickyBanner";
import { Stack } from "@bedrock-layout/stack";
import { Inline } from "@bedrock-layout/inline";
import { PadBox } from "@bedrock-layout/padbox";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useGroup } from "../utils/api-utils";

const ContainerPadding = styled(motion.div)`
box-sizing: border-box;
position: fixed;
left: -1rem;
// top: 3rem;
// border: 5px solid green;
// padding: 3rem;
// overflow: hidden;
z-index: 2;
`

const SlidingContainer = styled(motion.div)`
  position: fixed;
  left: -1rem;
  top: 3rem;
  border: .3rem solid ${clientThemes.primary};
  border-radius: 3px;
  box-shadow: 2.9px 5.8px 5.8px hsl(0deg 0% 0% / 0.41);
  background: rgba(255, 255, 255, 1);
  color: #fff;
  max-height: 80vh;
  z-index: 1000;

`

const InlineHeader = styled(Inline).attrs(({ align: "center", stretch: "start" }))`
  border: ${props => props.border};
  border-style: ${props => props.borderStyle};
  border-radius: ${props => props.borderRadius};
  color: ${props => props.color || "#000"};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  background: ${props => props.background};
  // text-transform: uppercase;
`

const ListScroll = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 3px;
  height: 60vh;
  overflow-y: scroll;
`
const ListItem = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: 5px;
  width: 250px;
  min-height: 35px;
  color: rgba(0, 0, 0, .1);
  white-space: nowrap;
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 400;
  // letter-spacing: -0.05em;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  text-overflow: ellipsis;
`

export default function SidePanel() {

  const [groups] = useAtom(groupsAtom);
  const [groupID] = useAtom(groupIDAtom);
  const [listHidden, setListHidden] = useAtom(listHiddenAtom)

  useLayoutEffect(() => {
    return () => {
      setListHidden(true)
    }
  }, [groups])



  const url = tabURLs['partners']
  const getUrl = (id) => `${url}/${id}`


  const listVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: { opacity: 1, x: 0 }
  }



  return (
    // <ReactPortal wrapperId="sliding-container-portal">
    <SlidingContainer
      initial="hidden"
      variants={listVariants}
      animate={listHidden ? "hidden" : "visible"}
      className="shadow-lg"
      onMouseLeave={() => setListHidden(true)}
    >
      <PadBox
        padding="lg"

      >

        <HeaderSection handleClick={() => setListHidden(true)} />
        <ListSection
          getUrl={getUrl}
          groups={groups}
          groupID={groupID}
          handleClick={() => setListHidden(true)} />
        <CreateNewGroupSection />
      </PadBox>
    </SlidingContainer>
    // </ReactPortal>

  )
}


function CreateNewGroupSection() {
  return (
    <PadBox padding={["md", "xl"]} style={{ background: "rgba(100, 100, 100, .1", borderRadius: "5px" }}>
      <InlineHeader fontSize=".9rem">
        <div style={{ fontWeight: 500 }}>Create New</div>
        <CreateNewGroup />
      </InlineHeader>
    </PadBox>
  )
}

function HeaderSection({ handleClick }) {
  return (
    <PadBox padding={["xs", "md"]}>
      <InlineHeader fontSize="1.1rem" fontWeight="500" color={tmsThemes.lightblue}>
        <div>Calendar Scenarios</div>
        <motion.div
          style={{ color: tmsThemes.lightblue, position: "absolute", top: 10, right: 10, }}
          // style={{ color: tmsThemes.lightblue, }}
          whileHover={{ color: "#000", scale: 1.2 }}>
          <IoCloseSharp size={35} onClick={handleClick} />
        </motion.div>
      </InlineHeader>
    </PadBox>
  )
}

function ListSection({ getUrl, groups, groupID, handleClick }) {



  return (
    <PadBox padding="lg">
      <ListScroll>
        {groups.map(o => {
          return (
            <ListItemRef
              key={o?.id}
              active={groupID === o?.id}
            >
              <NavLink
                onClick={handleClick}
                to={getUrl(o?.id)}
                style={{ height: "100%", width: "100%" }}>
                {o?.["name"]}
              </NavLink>
            </ListItemRef>
          )
        })}

      </ListScroll>
    </PadBox>
  )
}

const ListItemRef = ({ active, children }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && active) {
      ref.current.scrollIntoView()
    }
  }, [ref.current, active])

  const listItemVariants = {
    inactive: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, .8)"
    },
    active: {
      backgroundColor: tmsThemes.lightblue,
      color: "#fff"
    },

  }

  return (
    <ListItem
      variants={listItemVariants}
      initial="inactive"
      animate={active ? "active" : "inactive"}
      ref={ref}
      whileHover="active"
    >
      {children}
    </ListItem>
  )
}