import { Inline } from "@bedrock-layout/inline"
import { PadBox } from "@bedrock-layout/padbox"
import { Stack } from "@bedrock-layout/stack"
import { AnimatePresence, LayoutGroup, motion, useCycle } from "framer-motion"
import { useCallback, useState } from "react"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"


export const MotionStack = motion(Stack)
export const MotionPadBox = motion(PadBox)

const MotionChevronRight = ({ state }) => {
    const variants = {
        closed: { rotate : 0},
        open: { rotate: 90 }
    }
    return (
        <motion.div
            style={{
                display: "flex",
                width: "max-content"
            }}
            whileHover={{ scale: 1.2}}
            initial={{ scale: 1}}
        >
            <motion.div
                animate={state}
                variants={variants}
            >
                <FaChevronRight />
            </motion.div>
        </motion.div>
    )
}


const MotionInline = motion(Inline)

export const RightChevronToggleBox = ({ children }) => {
    const [state, cycleState] = useCycle("closed", "open")

    const handleClick = () => cycleState()

    const variants = {
        open: { opacity: 1, },
        closed: { opacity: 0, }
    }


    return (
        <MotionStack gutter="md" 
            style={{ marginLeft: "-2rem" }} 
            layout>
            <MotionInline
                variants={variants}
                initial="open"
                onClick={handleClick}
                gutter="md"
                align="center"
                style={{
                    fontSize: ".7rem",
                    fontWeight: "300",
                }}
                layout
            >
                <MotionChevronRight state={state} /> Instructions
            </MotionInline>
            <AnimatePresence>
                {state === "open" && <motion.div
                    layout
                    variants={variants}
                    // initial="closed"
                    initial={false}
                    animate={state}
                    exit="closed"
                >
                    {children}
                </motion.div>}
            </AnimatePresence>
        </MotionStack>
    )
}

