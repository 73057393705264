import { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";


import ElementForm from "./ElementForm";
import { useElement, useUpdateElement, useDeleteElement } from '../../utils/api-utils';
import { FormLoadingScreen } from "../../components/shared/LoadingScreens";
import { useResetForecast } from "../InteractiveCalendar/useFetchForecast";
import { withModal } from "../Modals/Modal";
import { useAtom } from "jotai";
import { userAtom } from "../../App";

export default function ElementEdit({ handleModal }) {
  const { groupID, elementID } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const { data: element = {}, status, error } = useElement(elementID)
  const [formData, setFormData] = useState(element);

  const [username] = useAtom(userAtom)
  

  const { handleResetForecast } = useResetForecast()

  const { mutate: handleUpdateElement, isLoading: isUpdating } = useUpdateElement()


  const handleSubmit = useCallback( () => {

    const date_fields = [formData?.start_date, formData?.end_date];
    const date_check = date_fields.every( o => o.length === 10)

    if(date_check === false) {
      window.alert("Missing required fields. Please supply a start date and end date.")
      return
    }

    const info_fields = [formData?.category, formData?.subcategory, formData?.offer_construct].flat()
    const info_check = info_fields.every( o => o !== null)


    if(info_check === false) {
      window.alert("Missing required fields. Please select an option from all dropdowns.")
      return
    }

    const partner_fields = [formData?.partner, formData?.new_partner].flat()
    const partner_check = partner_fields.some(o => o !== null)

    if(partner_check === false) {
      window.alert("Missing partner name. Please choose existing partner or type new partner name.")
      return
    }

    setSubmitted(true)
    const copyOfData = { ...formData }
    delete copyOfData.createdAt
    delete copyOfData.updatedAt
    delete copyOfData.group

    const inputData = 
      {
        ...copyOfData, 
        createdBy: username, 
        editedBy: username,
        expectedVersion: copyOfData.version,
        groupID
      }


    handleUpdateElement(inputData)
  }, [formData, handleUpdateElement, setSubmitted, username, groupID])


  useEffect(() => {
    if (element) {
      setFormData(element);
    }
  }, [element]);


  useEffect(() => {
    if (submitted) {
      console.log("Resetting forecast.")
      handleResetForecast()
    }

  }, [submitted]);


  

  const {
    mutate: deleteElement,
    isLoading: isDeleting,
    isError: isDeleteError,
  } =  useDeleteElement()
 


  if (isUpdating || isDeleting || status === "loading") {
    return <FormLoadingScreen />
  }

  return (
    <ElementForm
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
      handleDelete={deleteElement}
      handleModal={handleModal}
    />
  );
}


export const ElementEditModal = withModal(ElementEdit);
