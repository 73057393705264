import { useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom"
import styled from "styled-components";

export function ReactPortal({ children, wrapperId = "react-portal-wrapper"}) {
    const [wrapperElement, setWrapperElement] = useState(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;

        if (!element) {
            systemCreated = true;
            element = createPortalWrapper(wrapperId)
        }

        setWrapperElement(element);


        return () => {
            if(systemCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }

    }, [wrapperId])

    if (wrapperElement === null) return null; 

    return createPortal(children, wrapperElement)
}


function createPortalWrapper(wrapperId) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}


const ModalStyled = styled.div`
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 999;
    padding: 40px 20px 20px;
`

const ModalContent = styled.div`
    width: 70%;
    height: 70%;
    background-color: #282c34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
`

export function NewModal({ children, isOpen, handleClose }) {

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey)
        }
    }, [handleClose])
    
    if (!isOpen) return null;

    return (
    <ModalStyled>
        <button onClick={handleClose} className="close-btn">
            Close
        </button>
        <ModalContent>{children}</ModalContent>
    </ModalStyled>
    )
}