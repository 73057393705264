import React, { useState, useMemo, useReducer } from 'react'

import './index.css'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'



import { timeFormat } from "d3";


import {
    Column,
    Table as ReactTable,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getGroupedRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'

import TablePaginationActions from './actions'
import { makeData, Person } from './makeData'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { HiChevronDown, HiChevronRight, HiMinusCircle, HiOutlineStop } from "react-icons/hi2"
import styled from 'styled-components'
import { Inline } from '@bedrock-layout/inline'


const formatYMD = timeFormat("%Y-%m-%d")

const StyledTableContainer = styled(TableContainer)`
    & *:focus {
        outline: none;
    }

    & * {
        font-size: .8rem;
    }


`



export function CustomReactTable({ data, columnDefs }) {
    const rerender = useReducer(() => ({}), {})[1]

    const columns = useMemo(() => columnDefs, [columnDefs])


    return (
        <>
            <LocalTable {...{ data, columns }} />
            <hr />
        </>
    )
}






function LocalTable({
    data,
    columns,
}) {



    const [grouping, setGrouping] = useState([])



    const table = useReactTable({
        data,
        columns,
        state: {
            grouping
        },
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        //
        debugTable: false,
    })

    const { pageSize, pageIndex } = table.getState().pagination

    return (
        <Box sx={{ width: '100%' }}>
            <StyledTableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <TableCell key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div style={{
                                                    fontWeight: 500,
                                                }}>
                                                    <Inline gutter="sm" align="center">
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {/* {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null} */}
                                                        {header.column.getCanGroup() ? (
                                                            // If the header can be grouped, let's add a toggle
                                                            <button
                                                                {...{
                                                                    onClick: header.column.getToggleGroupingHandler(),
                                                                    style: {
                                                                        cursor: 'pointer',

                                                                    },
                                                                }}
                                                            >

                                                                {header.column.getIsGrouped()
                                                                    ? (<div>
                                                                        <HiMinusCircle size={20} />
                                                                        {/* {header.column.getGroupedIndex()} */}
                                                                    </div>)
                                                                    : <HiOutlineStop size={20} />}
                                                            </button>
                                                        ) : null}{' '}
                                                    </Inline>
                                                </div>
                                            )}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <TableRow key={row.id} >
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <TableCell key={cell.id}
                                                style={{
                                                    fontSize: ".8rem",
                                                    fontWeight: 300,
                                                    background: cell.getIsGrouped()
                                                        ? 'rgba(245, 245, 245, 1)'
                                                        : cell.getIsAggregated()
                                                            ? 'rgba(245, 245, 245, 1)'
                                                            : cell.getIsPlaceholder()
                                                                ? 'rgba(245, 245, 245, 1)'
                                                                : 'white',
                                                }}
                                            >
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        <button
                                                            {...{
                                                                onClick: row.getToggleExpandedHandler(),
                                                                style: {
                                                                    cursor: row.getCanExpand()
                                                                        ? 'pointer'
                                                                        : 'normal',
                                                                },
                                                            }}
                                                        >
                                                            <Inline gutter="sm" align='center'>

                                                                {row.getIsExpanded() ? <HiChevronDown size={20} /> : <HiChevronRight size={20} />}{' '}
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}{' '}
                                                                ({row.subRows.length})
                                                            </Inline>
                                                        </button>
                                                    </>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    flexRender(
                                                        cell.column.columnDef.aggregatedCell ??
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
                component="div"
                count={table.getFilteredRowModel().rows.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onPageChange={(_, page) => {
                    table.setPageIndex(page)
                }}
                onRowsPerPageChange={e => {
                    const size = e.target.value ? Number(e.target.value) : 10
                    table.setPageSize(size)
                }}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    )
}
function Filter({
    column,
    table,
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    return typeof firstValue === 'number' ? (
        <div className="flex space-x-2">
            <InputBase
                type="number"
                value={(columnFilterValue)?.[0] ?? ''}
                onChange={e =>
                    column.setFilterValue((old) => [
                        e.target.value,
                        old?.[1],
                    ])
                }
                placeholder={`Min`}
                className="w-24 border shadow rounded"
            />
            <InputBase
                type="number"
                value={(columnFilterValue)?.[1] ?? ''}
                onChange={e =>
                    column.setFilterValue((old) => [
                        old?.[0],
                        e.target.value,
                    ])
                }
                placeholder={`Max`}
                className="w-24 border shadow rounded"
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    ) : (
        <InputBase
            value={(columnFilterValue ?? '')}
            onChange={e => column.setFilterValue(e.target.value)}
            placeholder={`Search...`}
            className="w-36 border shadow rounded"
            inputProps={{ 'aria-label': 'search' }}
        />
    )
}
