

import styled from "styled-components"
import { motion, AnimatePresence, useScroll } from "framer-motion";
// import { motion, AnimatePresence, useScroll } from "framer-motion";
import { timeMonth, timeFormat, timeTuesdays, timeTuesday, timeDays, timeDay } from "d3";
import { Week, WeeksHeader } from "./CalendarWeek";
import { Columns } from "@bedrock-layout/columns";
import { useEffect, useRef, forwardRef, useLayoutEffect, useCallback, useState } from "react";
import { flushSync } from "react-dom";
import { atom, useAtom } from "jotai";
import { groupAtom } from "../ScenarioGroups/ScenarioGroupsView";
import { Tooltip } from "@mui/material";
import { useHoverTimeout } from "../../hooks/useTimeout";
import { clientThemes } from "../../constants";
import { rangeAtom } from "./CalendarDay";


const formatMonYear = timeFormat("%b %Y");

const MonthScroll = forwardRef((props, ref) => {
    return (
        <MonthColumns ref={ref} {...props}></MonthColumns>
    )
})

const MonthColumns = styled(motion.div)`
    background-color: rgba(240, 240, 240, .2);
    border-radius: 10px;
    padding-bottom: 1rem;
    & *:hover {
        cursor: pointer;
    }
    overflow-x: scroll;
    display: flex;
    & > * + * {
        margin: 0rem 1rem;
        flex: 1;
    }

    ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: rgba(100, 100, 100, .1);
    -webkit-border-radius: 1ex;
    }

    ::-webkit-scrollbar-thumb {
    background: ${clientThemes.primary};
    -webkit-border-radius: 1ex;
    }

    ::-webkit-scrollbar-corner {
    background: #fff3;
    }
`

const MonthContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    // border-right: 1px dotted black;
    min-width: 30vw;
    background: ${props => props.background || "none"};
`

const WeeksContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    border: 2px solid red;
`


export const scrollOffsetAtom = atom(0)
const TOOLTIP_TIMEOUT = 3000


export const Month = ({ start, isLatest }) => {
    const ref = useRef(null);
    const [range, _] = useAtom(rangeAtom)

    const timeout = useRef(null) // to store a handle to timeout so that it can be cleared
    const [isShowing, setIsShowing] = useState(false) // tooltip show/hide state
    const title = range?.allowSelection ?  "Click ESC to cancel" : "Click to select range"

    const handleMouseEnter = () => {
        if (!isShowing) {
            setIsShowing(true) 
            timeout.current = setTimeout(() => setIsShowing(false), TOOLTIP_TIMEOUT) 
        }
    }


    const handleClick = useCallback(() => {
        if (timeout.current) {
            clearTimeout(timeout.current) 
            timeout.current = null
        }
        setIsShowing(true) 
        timeout.current = setTimeout(() => setIsShowing(false), TOOLTIP_TIMEOUT) 

    },[timeout.current, setIsShowing])


    const handleMouseOut = () => {
        if (isShowing) {
            setIsShowing(false) 
        }

        if (timeout.current) {
            clearTimeout(timeout.current) 
            timeout.current = null
        }

    }

    useEffect(() => () => {
        // when component unmounts, clear scheduled hiding - nothing to hide by this point=)
        if (timeout.current) {
            clearTimeout(timeout.current)
            timeout.current = null
        }
    }, [])

    // useEffect(() => {
    //     if (range?.allowSelection) {
    //         setTitle(`Click ESC to cancel`)
    //     } else {
    //         setTitle(TOOLTIP_TITLE)
    //     }
    //     console.log("changing title")
    // }, [range?.allowSelection, isShowing])


    const [group] = useAtom(groupAtom)
    const scrollToMonth = useCallback(() => {
        if (isLatest && ref.current) {
            ref.current.scrollIntoView(false)
        }
    }, [ref.current, isLatest])

    useLayoutEffect(() => {
        scrollToMonth()
    }, [isLatest, group])


    const end = timeMonth.offset(start, 1)
    const weeks = timeTuesdays(start, end);

    const variants = {
        hidden: { opacity: 0 },
        enter: { opacity: 1 },
    }


    return (
        <MonthContainer
            id={start}
            layout
            variants={variants}
            exit={{ opacity: 0 }}
            ref={ref}
        >
            <h1 style={{ fontSize: ".8rem", fontWeight: 300 }}>{formatMonYear(start)}</h1>
            <WeeksHeader start={weeks[0]} />
            <Tooltip
                title={title}
                open={isShowing}
                followCursor={true}
                placement="bottom-start"
                style={{
                    outline: "none",
                    padding: 5,
                }}>
                <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseOut}
                    onClick={handleClick}
                >
                    {weeks.map(o => {
                        return <Week key={o} start={o} />
                    })}
                </div>
            </Tooltip>
        </MonthContainer>
    )
}



export function areDatesEq($date1, $date2, format = "%Y-%m-%d") {
    const formatTime = timeFormat(format)
    const date1 = formatTime($date1)
    const date2 = formatTime($date2)

    return date1 === date2

}

export const Months = ({ months, dateInfo }) => {

    return (

            <MonthColumns layout>
                <AnimatePresence>
                    {months.length && months.map((o, i) => {
                        return (
                            <Month
                                key={o}
                                start={o}
                                isLatest={areDatesEq(o, dateInfo.maxMonth)}
                            />
                        )
                    })
                    }
                </AnimatePresence>

            </MonthColumns>
    )
}
