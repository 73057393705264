import React, { useMemo, useCallback, useEffect, useState } from "react";
import memoize from 'memoize-one';
import Timeline from './Timeline';

import Legend from '../examples/components/Legend';
import { prepLineData } from '../../../utils/chart-utils';
import * as d3 from "d3"
import { AnimatePresence, motion } from "framer-motion"

const formatYear = d3.timeFormat("%Y")

export function TimelineChart({ data, dateFilter }) {
  const [selectedItems, setSelectedItems] = useState([])
  const legendData = useMemo(() => prepLineData(data, dateFilter), [data, dateFilter])
  const [brushExtent, setBrushExtent] = useState(null)
  const [legendDisabled, setLegendDisabled] = useState(false)

  useEffect(() => {
    setBrushExtent(null)
  }, [data])

  useEffect(() => {
    if(brushExtent !== null) {
      setLegendDisabled(true)
    }
    if(brushExtent === null) {
      setLegendDisabled(false)
    }
  }, [brushExtent])

  useEffect(() => {
    const items = legendData.map(d => d.name)
    setSelectedItems(items)
  }, [legendData])

  const onChangeSelection = useCallback((name) => {
    const newSelection = selectedItems.includes(name)
      ? selectedItems.filter(item => item !== name)
      : [...selectedItems, name]
    setSelectedItems(newSelection)
  }, [selectedItems])



  if (!data || !legendData) {
    return <p>Loading...</p>
  }

  const groupData = legendData.filter(
    o => selectedItems.includes(o.name)
  )

  const filterItems = (items) => items.filter(d => {
    const date = d.datetime
    return date > brushExtent[0] && date < brushExtent[1]
  })

  const filteredData = brushExtent
    ? groupData
      .map(d => ({ ...d, items: filterItems(d.items) }))
    : [...groupData];


  return (
    <motion.div className="flex flex-col select-none">
      <Legend
        data={legendData}
        selectedItems={selectedItems}
        onChange={onChangeSelection}
        disabled={legendDisabled}
      />
      <AnimatePresence>
        <Timeline
          data={filteredData}
          selectedItems={selectedItems}
          setBrushExtent={setBrushExtent}
        />
      </AnimatePresence>
    </motion.div>


  );
}


export const MemoizedTimelineChart = memoize(TimelineChart);
