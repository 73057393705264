import { shortISO } from "./date-wrangler";

import { filterObject, sortByDate } from "./data-formatting";
import { API, graphqlOperation } from '@aws-amplify/api';
import { listGroups, listElements, getGroup, getElement } from "../graphql/queries"
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { createElement, createGroup, deleteGroup, deleteElement, updateElement, updateGroup, createAudience, updateAudience } from '../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";



export function useElement(elementID) {

  return useQuery(["element", elementID],
    async () => {

      try {
        const { data } = await API.graphql(
          graphqlOperation(getElement, { id: elementID })
          );
          
          return data?.getElement
        } 
        catch (error) {
          throw new Error(error?.message)
        }
    },
    {
      enabled: !!elementID,
      // retry: NUM_RETRY,
      onError: (error) => {
        if(error?.message === "No current user") {
          window.alert("Authentication expired. Please log back in to resume where you left off.")
          Auth.federatedSignIn();
        }
      },
    })
}



export function useGroups(refetch) {
  return useQuery("groups",
    async () => {
      try {
        const { data } = await API.graphql(graphqlOperation(listGroups));
        const { listGroups: { items } } = await data;
        return items.sort((a, b) => sortByDate(a, b, "createdAt"));
      }
      catch (error) {
        throw new Error(error?.message)
      }
    },
    {
      refetchOnWindowFocus: false,
      // retry: NUM_RETRY,
      onError: (error) => {
        if(error?.message === "No current user") {
          window.alert("Authentication expired. Please log back in to resume where you left off.")
          Auth.federatedSignIn();
        }
      },
    }
  )
}


export function useGroup(groupID) {
  const queryClient = useQueryClient();

  return useQuery(["group", groupID],
    async () => {
      try {

        const { data } = await API.graphql(
          graphqlOperation(getGroup, { id: groupID })
          );
          
          const { getGroup: groupData } = data;
          return groupData
        }
        catch (error) {
          throw new Error(error?.message)
        }
    },
    { 
      enabled: !!groupID,
      initialData: queryClient.getQueryData(["group", groupID]),
      refetchOnWindowFocus: true,
      // retry: NUM_RETRY,
      onError: (error) => {
        if(error?.message === "No current user") {
          window.alert("Authentication expired. Please log back in to resume where you left off.")
          Auth.federatedSignIn();
        }
      },
    }
    )
}




async function addGroup(newGroup) {
  try {
    await API.graphql(graphqlOperation(createGroup, { input: newGroup }));
  } catch (error) {
    throw new Error(error?.message)
  }
}

export function useCreateGroup() {
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const mutation = useMutation(addGroup, {

    onSuccess: (resp, group) => {
      const { id } = group;
      navigate(`/partners/${id}`)
      queryClient.invalidateQueries("groups")

    },
    onError: (error) => {
      if(error?.message === "No current user") {
        window.alert("Authentication expired. Please log back in to resume where you left off.")
        Auth.federatedSignIn();
      }
    },
  })

  return {
    addGroup: mutation.mutate,
    isAddingGroup: mutation.isLoading,
  }
}



async function removeGroup(group) {
  const { id, version } = group;
  try {
    await API.graphql(graphqlOperation(deleteGroup,
      { input: { id: id, expectedVersion: version } }));
  } catch (error) {
    throw new Error(error?.message);
  }
}

export function useDeleteGroup(url) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useMutation(removeGroup, {
    onMutate: async item => {
      const { id } = item;

      const queryKey = "groups"

      await queryClient.cancelQueries(queryKey);
  
      // // Snapshot the previous value
      const snapshot = queryClient.getQueryData(queryKey);
      const optimistic = [...snapshot.filter(o => o?.id !== id)];

      queryClient.setQueryData(queryKey, optimistic)
      console.log("optimistic", optimistic);
  
      return { snapshot }
    },
    onError: (error, element, context) => {
      if(error?.message === "No current user") {
        window.alert("Authentication expired. Please log back in to resume where you left off.")
        Auth.federatedSignIn();
      }
      console.log("Error deleting element. Restoring context", context?.snapshot);
      queryClient.setQueryData("groups", context.snapshot);
    },
    onSettled: async (data, error, variables, context) => {
      await queryClient.invalidateQueries("groups")
      await queryClient.invalidateQueries(["group"])
      navigate(url)
    },
  })
  return mutation
}





async function removeElement(element) {
  const { id, version: expectedVersion } = element;
  try {
    await API.graphql(graphqlOperation(deleteElement, 
      { input: { id, expectedVersion } }));
    console.log("deleting element id", id)
  } catch (error) {
    throw new Error(error?.message);
    
  }
}



export function useDeleteElement() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useMutation(removeElement, {

    onSuccess: async (resp, element) => {
      const { groupID } = await element;
      await queryClient.invalidateQueries("groups")
      await queryClient.invalidateQueries(["group", groupID])
      await queryClient.invalidateQueries("elements")
      navigate(groupID ? `/partners/${groupID}` : "/partners")

    },
    onError: (error, element, context) => {
      if(error?.message === "No current user") {
        window.alert("Authentication expired. Please log back in to resume where you left off.")
        Auth.federatedSignIn();
      }
    },
  })

  return mutation
}



async function addElement(info) {

  try {
    await API.graphql(
      graphqlOperation(createElement, { input: info  })
    );
  } catch (error) {
    throw new Error(error?.message)
  }

}

export function useAddElement() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useMutation(addElement, {

    onSuccess: (resp, variables) => {
      const { groupID } = variables;
      queryClient.invalidateQueries(["group", groupID])
      navigate(`/partners/${groupID}`)
    },
    onError: (error) => {
      if(error?.message === "No current user") {
        window.alert("Authentication expired. Please log back in to resume where you left off.")
        Auth.federatedSignIn();
      }
    },
  })

  return mutation
}

async function addAudience(newInput) {
  try {
    await API.graphql(graphqlOperation(createAudience, { input: newInput }));
  } catch (error) {
    console.log("Failed creating audience input:", error);
  } 
}

export function useCreateAudience() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useMutation(addAudience, {

    onSuccess: (resp, group) => {
      // queryClient.invalidateQueries("groups")
      // queryClient.invalidateQueries("group")
      // const groups = queryClient.getQueryData("groups") || [];
      // queryClient.setQueryData(key, [...groups, group]);
      navigate(`/audience`)

    }
  })

  return {
    createAudience: mutation.mutate,
    isCreatingAudience: mutation.isLoading,
  }
}


async function handleElementUpdate(newData) {
  try {
    await API.graphql(
      graphqlOperation(updateElement, {
        input: {
          ...newData,
        }
      })
    );
  } catch (error) {
    throw new Error(error?.message);
  }
}


export function useUpdateElement() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useMutation(handleElementUpdate, {

    onSuccess: async (resp, variables) => {
      const { id: elementID, groupID } = await variables
      await queryClient.invalidateQueries("groups")
      await queryClient.invalidateQueries(["group", groupID])
      await queryClient.invalidateQueries(["element", elementID])
      navigate(`/partners/${groupID}`)
    },
    onError: (error) => {
      if(error?.message === "No current user") {
        window.alert("Authentication expired. Please log back in to resume where you left off.")
        Auth.federatedSignIn();
      }
    },
  })

  return mutation
}



async function handleGroupUpdate(newData) {
  try {
    await API.graphql(
      graphqlOperation(updateGroup, {
        input: {
          ...newData,
        },
      })
    );
  } catch (error) {
    throw new Error(error?.message)
  }
}



export function useUpdateGroup() {
  const queryClient = useQueryClient()
  
  const mutation = useMutation(handleGroupUpdate, {

    onError: (error, element, context) => {
      if(error?.message === "No current user") {
        window.alert("Authentication expired. Please log back in to resume where you left off.")
        Auth.federatedSignIn();
      }
    },
    onSuccess: (resp, variables) => {
      const { id } = variables;
      
      queryClient.setQueryData(
        ["group", id], 
        old => ({...old, ...variables})
      )

      queryClient.invalidateQueries(["group", id])
      queryClient.invalidateQueries("groups")
    }
  })

  return {
    updateFn: mutation.mutate,
    isUpdating: mutation.isLoading,
    updateError: mutation.error,
  }
}



// export function useUpdateGroup() {
//   const queryClient = useQueryClient()
//   const mutation = useMutation(handleGroupUpdate, {

//     onSuccess: (resp, variables) => {

//       const { id } = variables;
//       queryClient.invalidateQueries(["group", id])
//       queryClient.invalidateQueries("groups")
//     }
//   })

//   return {
//     updateFn: mutation.mutate,
//     isUpdating: mutation.isLoading,
//     updateError: mutation.error,
//   }
// }






