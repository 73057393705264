import React, { useState, useRef, useEffect } from "react"
import { scaleDiscontinuous, discontinuityRange } from 'd3fc-discontinuous-scale';
import * as d3 from "d3"

import "./Timeline.css";
import { useChartDimensions } from "../Chart/utils"
import { AnimatePresence, motion } from "framer-motion";
import { materialFont } from "../../../constants";



const formatDate = d3.timeFormat("%m/%d/%y (%Hh)")

function getXDomain(data, xKey) {
  const xMin = d3.min(data, ({ items }) => d3.min(items, o => o[xKey]))
  const xMax = d3.max(data, ({ items }) => d3.max(items, o => o[xKey]))

  if (!xMin || !xMax) return []

  return [xMin, xMax]
}


function getYDomain(data, yKey) {
  const yMin = d3.min(data, ({ items }) => d3.min(items, o => o[yKey] ))
  const yMax = d3.max(data, ({ items }) => d3.max(items, o => o[yKey] ))
  if (yMin === undefined || yMax === undefined) return []
  return [yMin, yMax]
}


const Timeline = ({ data = [], margin = {}, selectedItems, setBrushExtent }) => {

  const svgRef = useRef();
  const brushRef = useRef();
  const [wrapperRef, dimensions] = useChartDimensions()

  const {
    boundedWidth,
    boundedHeight,
  } = dimensions

  const [xKey, yKey] = ["datetime", "mean"] 
  const [xMin, xMax] = getXDomain(data, xKey)
  const xScale  = d3.scaleTime().domain([xMin, xMax]).range([0, boundedWidth])

  const [yMin, yMax] = getYDomain(data, yKey)
  const yScale =  d3.scaleLinear().domain([yMin, yMax]).range([boundedHeight, 0])

  useEffect(() => {
    const brush = d3.brushX()
      .extent([
        [0, 0], [boundedWidth, boundedHeight]
      ])
    brush(d3.select(brushRef.current))
    brush.on("brush end", (event) => {
      if (event.selection ) {
        const timeSelection = event.selection.map(xScale.invert);
        setBrushExtent(timeSelection);
      } else {
        setBrushExtent(null)
      }

  }) 
  }, [boundedWidth, boundedHeight, data])



  const chartVariants = {
    on: {
      opacity: 1,
    },
    off: {
      opacity: 0,
    }
  }



  return (

    <motion.div
      className={`h-50v md:h-60v`}
      // style={{ border: "1px solid red" }}
      ref={wrapperRef}
    >
      <svg
        className="Chart"
        width={dimensions.width}
        height={dimensions.height}
        ref={svgRef}
      >
        <motion.g
          className="g-content"
          transform={`translate(${dimensions.marginLeft + 15}, ${dimensions.marginTop})`}
          variants={chartVariants}
          initial="on"
          exit="off"
          animate="on"
        >

          <ZoomableLine
            data={data}
            xScale={xScale}
            yScale={yScale}
            dimensions={dimensions}
            xKey={xKey}
            yKey={yKey}
          />
          <g ref={brushRef} />
        </motion.g>
      </svg>
    </motion.div>
  )
}


function ZoomableLine({ data, xScale, yScale, dimensions, zoomState, xKey, yKey }) {

  return (
    <>
      <AnimatePresence>
        {data.map((d, i) => (
          <Line
            key={d.id}
            data={d}
            xScale={xScale}
            yScale={yScale}
            xKey={xKey}
            yKey={yKey}
          />
        )
        )}
      </AnimatePresence>
      <AxisX
        xScale={xScale}
        dimensions={dimensions}
      />
      <AxisY
        yScale={yScale}
        dimensions={dimensions}
      />
    </>
  )
}


export function AxisX({ xScale, dimensions }) {
  const ref = useRef()

  const { boundedWidth, boundedHeight, marginLeft, marginBottom } = dimensions

  useEffect(() => {

    if (xScale) {
      const xAxisG = d3.select(ref.current)
      const xAxis = d3.axisBottom(xScale).tickFormat(formatDate).ticks(5)
      xAxisG.call(xAxis)
    }
  })

  return (
    <g
      className="Axis AxisHorizontal"
      style={{ 
        fontSize: 12,
      }}
      transform={`translate(0, ${boundedHeight})`}
      ref={ref} />
  )
}

export function AxisY({ yScale, dimensions }) {
  const ref = useRef()

  const { marginLeft, marginBottom } = dimensions

  useEffect(() => {
    if (yScale) {

      const yAxisG = d3.select(ref.current)
      const yAxis = d3.axisLeft(yScale)
      yAxisG.call(yAxis)
    }
  })


  return (
    <g
      style={{ 
        fontSize: 12,
      }}
      transform={`translate(0, 0)`}
      ref={ref}
    />
  )
}

function Line({ data, xScale, yScale, xKey, yKey }) {

  const lineGenerator = d3.line()
    .x(d => xScale(d[xKey]))
    .y(d => yScale(d[yKey]))
    .curve(d3.curveMonotoneX)

  const line = lineGenerator(data.items)

  const variants = {
    on: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: .5,
        ease: "easeOut",
      },
    },
    off: {
      opacity: 0,
      pathLength: 0,
      transition: {
        duration: .5,
        ease: "easeOut",
      },
    }
  }


  return (
    <motion.path
      name={data.id}
      key={data.id}
      d={line}
      exit="off"
      initial="off"
      animate="on"
      variants={variants}
      stroke={data.color}
      strokeDasharray="0 1"
      strokeWidth="1"
      fill="none"
    />
  )

}

export default Timeline
